<div class="table-section">
  <div class="table-section__header">
    <div class="table-section__header-buttons">
      <app-button [buttonData]="createProjectBtn" (click)="openCreateProjectPopup()"></app-button>
    </div>

    <div class="table-section__header-search">
      <input type="text" [(ngModel)]="clientService.searchQuery" placeholder="Search" />
      <span class="icon-Search"></span>
      <span
        class="icon-Close close-icon hide-desktop"
        *ngIf="clientService.searchQuery != ''"
        (click)="clientService.clearSearchQuery()"></span>
    </div>
  </div>
  <div class="table-section__body">
    <table
      id="table"
      class="table"
      *ngIf="tableData.length > 0; else emptyTable"
      matSort
      matSortDisableClear
      appClickedOutside
      (matSortChange)="sortData($event)"
      (clickOutside)="clickedOutside()">
      <thead>
        <tr>
          <th mat-sort-header="name">Name</th>
          <th mat-sort-header="createdOn" class="hide-mobile hide-tablet">Create date</th>
          <th mat-sort-header="render">Render</th>
          <th mat-sort-header="jobCost" class="hide-mobile hide-tablet">Est. Price</th>
          <th class="hide-mobile hide-tablet"></th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let job of tableData | search : searchColumns : clientService.searchQuery; trackBy: trackByData">
          <td class="link" (click)="navigateTo(job)">
            <div class="center" [title]="job.name">
              <span class="file-type-icon icon-folder"></span>
              {{ additionalService.cropFileNameLength(job.name) }}
            </div>
          </td>
          <td class="link hide-mobile hide-tablet" *ngIf="job.createdOn; else empty" (click)="navigateTo(job)">
            {{ job.createdOn | date : 'dd.MM.yy, HH:mm' }}
          </td>
          <td class="table__render" [ngClass]="{ 'hide-mobile': !job.finishedFramesCount }">
            <div class="table__render-result">
              <span> {{ additionalService.getRenderPercent(job.framesCount, job.finishedFramesCount) }}%</span>
              <div class="warnings" *ngIf="job.warnings?.length > 0">
                <span
                  *ngFor="let warning of groupWarnings(job.warnings) | keyvalue | slice : 0 : 2"
                  class="warning"
                  [matTooltip]="warning.value"
                  [matTooltipPosition]="'below'"
                  [matTooltipClass]="'custom-tooltip'">
                  {{ warning.key }}<span class="icon-Info"></span>
                </span>
              </div>
              <span class="frames hide-mobile hide-tablet"
                >{{ job.finishedFramesCount || 0 }} / {{ job.framesCount || 0 }}</span
              >
              <span class="frames hide-desktop"
                ><span>{{ job.amountSpent || 0 | number : '1.2-2' }} / {{ job.jobCost || 0 | number : '1.2-2' }}</span
                ><span class="icon-price icon-Dashboard"></span
              ></span>
            </div>
            <app-progress-bar
              [percent]="
                additionalService.getRenderPercent(job.framesCount, job.finishedFramesCount)
              "></app-progress-bar>
          </td>
          <td class="hide-mobile hide-tablet nowrap">
            <div class="center">
              <span>{{ job.amountSpent || 0 | number : '1.2-2' }} / {{ job.jobCost || 0 | number : '1.2-2' }}</span
              ><span class="icon-price icon-Dashboard"></span>
            </div>
          </td>
          <td class="table__status-buttons hide-mobile hide-tablet">
            <app-status-button
              [jobData]="job"
              [isDisabled]="storageApiService.isJobFileUploadInprogress(job.id)"></app-status-button>
          </td>
          <td class="table__menu">
            <div class="table__menu-wrapper">
              <span class="table__menu-icon icon-Dots-2" (click)="toggleMenu(job, $event)"></span>
              <ul *ngIf="job?.isMenuOpen" class="table__menu-items">
                <li (click)="openRenameProjectPopup(job)">
                  <span class="name">Rename</span>
                  <span class="icon icon-Edit"></span>
                </li>
                <li (click)="removeItem(job, $event)">
                  <span class="name">Remove</span>
                  <span class="icon icon-Close-Bold"></span>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <ng-template #emptyTable>
      <div class="table__empty">
        <span *ngIf="!(loaderService.loading$ | async)">No projects found</span>
      </div>
    </ng-template>

    <ng-template #empty>
      <td>-</td>
    </ng-template>
  </div>
</div>
