<div class="forgot-password" *ngIf="!isMessageSent">
  <div class="header">
    <h1>Reset Password</h1>
    <h2>
      Enter the email associated with your account and we`ll send an email with insturctions to reset your password.
    </h2>
    <span class="close-icon icon-Close-Bold" mat-dialog-close></span>
  </div>
  <form [formGroup]="form" class="form form-modal" autocomplete="off">
    <div class="form__field-group">
      <label for="email">Email</label>
      <input id="email" formControlName="email" type="text" placeholder="Enter email" />

      <ng-container *ngIf="form.get('email')?.invalid && form.get('email')?.dirty">
        <span *ngIf="form.get('email')?.hasError('required')" class="form__error">Email should be present </span>

        <ng-container
          *ngIf="
            form.get('email')?.hasError('minlength') || form.get('email')?.hasError('maxlength');
            else email_pattern
          ">
          <span class="form__error">
            Email should have
            <mark>{{ emailMinLength }} - {{ emailMaxLength }}</mark>
            characters. You currently typed
            <mark>{{ form.get('email')?.value.length }}</mark>
          </span>
        </ng-container>

        <ng-template #email_pattern>
          <ng-container *ngIf="form.get('email')?.hasError('email')">
            <span class="form__error">Valid email address required</span>
          </ng-container>
        </ng-template>
      </ng-container>
    </div>

    <app-button
      [attr.disabled]="form.invalid"
      [disabled]="form.invalid"
      (click)="onSubmit()"
      [buttonData]="submitBtn"></app-button>
  </form>
</div>
<div class="forgot-password-success" *ngIf="isMessageSent">
  <img src="./assets/imgs/email.png" alt="" />
  <h2>We have sent a password recover insturctions to your email</h2>
  <app-button (click)="onClose()" [buttonData]="closeBtn"></app-button>
</div>
