import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ChangePasswordModalComponent } from '../change-password-modal/change-password-modal.component';
import { AuthService } from '../../../services/auth.service';
import { ProfileInfo, Button } from '../../../interfaces';
import { BUTTON_SIZE, BUTTON_THEME } from '../../../enums';

@Component({
  selector: 'app-profile-settings-modal',
  templateUrl: './profile-settings-modal.component.html',
  styleUrls: ['./profile-settings-modal.component.scss']
})
export class ProfileSettingsModalComponent implements OnInit, OnDestroy {
  profileImage: string | any = '';
  newProfileImage: File | null = null;
  oldUsername: string = '';
  oldEmail: string = '';
  emailMinLength = 3;
  emailMaxLength = 200;
  usernameMinLength = 2;
  usernameMaxLength = 100;

  form: FormGroup = new FormGroup({
    username: new FormControl('', [
      Validators.required,
      Validators.minLength(this.usernameMinLength),
      Validators.maxLength(this.usernameMaxLength),
      Validators.pattern('^\\d*\\S+\\d*$')
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.minLength(this.emailMinLength),
      Validators.maxLength(this.emailMaxLength),
      Validators.email
    ])
  });

  private profileSubscription: Subscription | undefined;

  readonly submitBtn: Button = {
    name: 'Save',
    size: BUTTON_SIZE.LG
  };

  readonly changePasswordBtn: Button = {
    name: 'Change Password',
    size: BUTTON_SIZE.LG,
    theme: BUTTON_THEME.DARK,
    iconRight: 'icon-Change-Password'
  };

  constructor(
    private authService: AuthService,
    private toastrService: ToastrService,
    public dialogService: MatDialog,
    public dialogRef: MatDialogRef<ProfileSettingsModalComponent>
  ) {}

  ngOnInit(): void {
    this.profileSubscription = this.authService.profileInfo$.subscribe((response) => {
      if (response) {
        this.oldUsername = response.username!;
        this.oldEmail = response.email!;
        this.profileImage = response.avatarUrl!;
        this.form.get('username')?.setValue(response.username);
        this.form.get('email')?.setValue(response.email);
      }
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const newUsername = this.form.get('username')?.value;
      const newEmail = this.form.get('email')?.value;

      let clientData: ProfileInfo = {};

      if (newUsername !== this.oldUsername) {
        clientData.username = newUsername;
      }

      if (newEmail !== this.oldEmail) {
        clientData.email = newEmail;
      }

      if (this.newProfileImage) {
        this.authService.updateAvatar(this.newProfileImage).subscribe({
          next: (response) => {
            if (response.success) {
              this.toastrService.success('New user photo uploaded successfully!');
              if (Object.keys(clientData).length > 0) {
                this.updateUserData(clientData);
              } else {
                this.authService.getProfileInfoData();
                this.dialogRef.close();
              }
            } else {
              this.toastrService.error(response.error?.description);
            }
          },
          error: (error) => {
            this.toastrService.error(error.message);
          }
        });
      } else {
        if (Object.keys(clientData).length > 0) {
          this.updateUserData(clientData);
        } else {
          this.toastrService.warning('There are no changes to be saved.');
        }
      }
    }
  }

  updateUserData(clientData: ProfileInfo) {
    this.authService.updateProfile(clientData).subscribe({
      next: (response) => {
        if (response.success) {
          this.toastrService.success('User data updated successfully!');
          this.authService.getProfileInfoData();
          this.dialogRef.close();
        } else {
          this.toastrService.error(response.error?.description);
        }
      },
      error: (error) => {
        this.toastrService.error(error.message);
      }
    });
  }

  uploadPhoto(event: any) {
    const allowedExtensions = ['.jpeg', '.jpg', '.png'];
    const selectedFile = event.target.files[0];
    const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();

    if (!allowedExtensions.includes(`.${fileExtension}`)) {
      this.toastrService.warning('Invalid file type. Please select a .jpeg, .jpg, or .png file.');
      event.target.value = '';
      return;
    }

    this.newProfileImage = event.target.files[0];

    console.log('File:', this.newProfileImage);
    event.target.value = '';

    const fileReader = new FileReader();
    fileReader.readAsDataURL(this.newProfileImage!);
    fileReader.onload = () => {
      this.profileImage = fileReader.result;
    };
  }

  openChangePasswordPopup() {
    this.dialogService.closeAll();
    setTimeout(() => {
      this.dialogService.open(ChangePasswordModalComponent, { panelClass: 'dialog-overlay-pane' });
    }, 100);
  }

  ngOnDestroy(): void {
    this.profileSubscription?.unsubscribe();
  }
}
