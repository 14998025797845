import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { loadStripe } from '@stripe/stripe-js';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from '../../shared/services/payment.service';
import { AdditionalService } from '../../shared/services/additional.service';
import { BalanceService } from '../../shared/services/balance.service';
import { AuthService } from '../../shared/services/auth.service';
import { LoaderService } from '../../shared/services/loader.service';
import { Button } from '../../shared/interfaces';
import { BUTTON_THEME, BUTTON_TYPE, BUTTON_SIZE } from '../../shared/enums';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss']
})
export class CheckoutPageComponent implements OnInit, OnDestroy {
  private stripePromise = loadStripe(environment.stripeApiKey);
  stripe: any;
  elements: any;
  paymentId: string = '';
  amount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private balanceSubscription: Subscription | undefined;
  private clientSubscription: Subscription | undefined;
  private paymentSubscription: Subscription | undefined;
  private profileSubscription: Subscription | undefined;

  form: FormGroup = new FormGroup({
    email: new FormControl(''),
    amount: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[0-9.]+$/),
      Validators.min(1),
      Validators.max(100000)
    ])
  });

  makePaymentBtn: Button = {
    name: 'Buy Coins',
    size: BUTTON_SIZE.XL
  };

  backBtn: Button = {
    name: 'Back',
    theme: BUTTON_THEME.DARK,
    type: BUTTON_TYPE.BACK,
    size: BUTTON_SIZE.XS,
    iconLeft: 'icon-Arrow-Left'
  };

  constructor(
    private router: Router,
    private paymentService: PaymentService,
    private additionalService: AdditionalService,
    private balanceService: BalanceService,
    private authService: AuthService,
    private toastrService: ToastrService,
    public loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.clientSubscription = this.paymentService.getClientSecret().subscribe({
      next: (response) => {
        if (response.success && response.data) {
          this.paymentId = response.data.id;
          this.loaderService.showLoader();
          this.invokeStripe(response.data.clientSecret);
        } else {
          this.toastrService.error(response.error?.description);
        }
      },
      error: (error) => {
        this.toastrService.error(error.message);
      }
    });

    this.profileSubscription = this.authService.profileInfo$.subscribe((response) => {
      if (response.email) {
        this.form.get('email')?.setValue(response.email);
      }
    });
  }

  async invokeStripe(clientSecret: string) {
    this.stripe = await this.stripePromise;
    this.elements = this.stripe?.elements({
      clientSecret: clientSecret,
      appearance: {
        theme: 'night',
        variables: {
          colorPrimary: '#ffffff',
          colorBackground: '#0E0E10',
          colorText: '#898796',
          colorDanger: '#ef3333',
          fontFamily: 'Roboto, system-ui, sans-serif',
          spacingUnit: '5px',
          borderRadius: '5px',
          fontSizeBase: '15px',
          fontSizeSm: '13px',
          fontWeightNormal: '500',
          spacingGridRow: '5px',
          spacingGridColumn: '10px'
        },
        rules: {
          '.Tab': {
            marginTop: '15px',
            marginBottom: '15px'
          },
          '.Tab:focus': {
            outline: 'none',
            boxShadow: 'none'
          },
          '.Tab--selected': {
            backgroundColor: '#16161a',
            border: '1px solid #898796'
          },
          '.Tab--selected, .Tab:hover, .TabLabel--selected, .TabLabel:hover': {
            color: '#fff'
          },
          '.TabIcon--selected, .TabIcon:hover': {
            fill: '#fff'
          },
          '.Label': {
            marginTop: '15px',
            textTransform: 'uppercase'
          },
          '.Input': {
            color: '#fff'
          },
          '.Input:focus': {
            boxShadow: 'none'
          },
          '.Input--invalid': {
            color: '#fff',
            borderWidth: '1px',
            boxShadow: 'none'
          },
          '.Input::placeholder': {
            color: '#696774'
          }
        }
      },
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700'
        }
      ]
    });
    const paymentElement = this.elements?.create('payment');
    paymentElement?.mount('#payment-element');
    this.loaderService.hideLoader();
  }

  submitPayment() {
    this.loaderService.showLoader();
    //this.elements.update({ amount: this.amount$.getValue() * 100, mode: 'payment', currency: 'usd' });

    this.paymentSubscription = this.paymentService
      .updatePaymentAmount(this.amount$.getValue(), this.paymentId)
      .subscribe({
        next: (response) => {
          if (response.success && response.data) {
            this.elements.fetchUpdates().then(() => {
              this.createPayment().then(() => {
                this.balanceService.refreshBalance();
              });
            });
          } else {
            this.toastrService.error(response.error?.description);
          }
        },
        error: (error) => {
          this.toastrService.error(error.message);
        }
      });
  }

  async createPayment() {
    const elements = this.elements;
    const redirect_url = window.location.protocol + '//' + window.location.host + '/wallet';

    const { error } = await this.stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: redirect_url
      }
    });

    this.loaderService.hideLoader();

    if (error.type === 'card_error' || error.type === 'validation_error') {
      this.toastrService.error(error.message);
    } else {
      this.toastrService.error('An unexpected error occurred');
    }
  }

  redirectToWallet() {
    this.router.navigateByUrl('wallet');
  }

  onAmountChange(event: any) {
    const amount = this.additionalService.normalizeAmount(event.data, event.target.value, 2);
    this.additionalService.previousValue$.next(amount);
    this.form?.get('amount')?.setValue(amount);
    const convertedAmount = parseFloat(amount);
    this.amount$.next(isNaN(convertedAmount) ? 0 : convertedAmount);
  }

  ngOnDestroy(): void {
    this.balanceSubscription?.unsubscribe();
    this.clientSubscription?.unsubscribe();
    this.paymentSubscription?.unsubscribe();
    this.profileSubscription?.unsubscribe();
  }
}
