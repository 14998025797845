import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { StorageApiService } from '../../../shared/services/storage-api.service';
import { ProjectService } from '../../../shared/services/project.service';
import { ClientService } from '../../../shared/services/client.service';
import { Button } from '../../../shared/interfaces';
import { BUTTON_THEME, BUTTON_TYPE, BUTTON_SIZE } from '../../../shared/enums';

@Component({
  selector: 'app-project-item-page',
  templateUrl: './project-item-page.component.html',
  styleUrls: ['./project-item-page.component.scss']
})
export class ProjectItemPageComponent implements OnInit, OnDestroy {
  private activatedRouteSubscription: Subscription = {} as Subscription;

  readonly backBtn: Button = {
    name: 'Back',
    theme: BUTTON_THEME.DARK,
    type: BUTTON_TYPE.BACK,
    size: BUTTON_SIZE.XS,
    iconLeft: 'icon-Arrow-Left'
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    public clientService: ClientService,
    public storageApiService: StorageApiService,
    public projectService: ProjectService
  ) {}

  ngOnInit(): void {
    this.clientService.isProjectsPage = false;
    this.activatedRouteSubscription = combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.queryParams
    ]).subscribe(([params, queryParams]) => {
      const jobId = Number.parseInt(params['id']);
      this.storageApiService.path = queryParams['path'] || '';
      this.projectService.selectJob(jobId);
    });
  }

  ngOnDestroy(): void {
    this.storageApiService.isRenderResultsSectionOpen = false;
    this.projectService.clearSelectedJobData();
    this.activatedRouteSubscription.unsubscribe();
  }
}
