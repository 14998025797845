<div class="promocode">
  <div class="header">
    <h1>Promo code</h1>
    <span class="close-icon icon-Close-Bold" mat-dialog-close></span>
  </div>
  <form [formGroup]="form" class="form form-modal" autocomplete="off">
    <div class="form__field-group">
      <input formControlName="promocode" type="text" placeholder="Enter promo code" />
      <span *ngIf="form.get('promocode')?.hasError('required') && form.get('promocode')?.dirty" class="form__error">
        Promo code should be present
      </span>
    </div>
    <app-button
      [attr.disabled]="form.invalid"
      [disabled]="form.invalid"
      (click)="getCoins()"
      [buttonData]="submitBtn"></app-button>
  </form>
</div>
