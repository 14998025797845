import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from './client.service';
import { StorageApiService } from './storage-api.service';
import { AuthService } from './auth.service';
import { BalanceService } from './balance.service';
import { BalanceWarningModalComponent } from '../components/modals/balance-warning-modal/balance-warning-modal.component';
import { ClientJobListWithDetails, ClientJobItem } from '../interfaces';
import { JOB_STATUS } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private readonly DATA_REFRESH_TIME = 1000 * 15;
  private clientOutputResourcesSubscription: Subscription | undefined;
  private clientInputResourcesSubscription: Subscription | undefined;
  private clientDetailsSubscription: Subscription | undefined;
  private selectedJobDataSubscription: Subscription | undefined;
  private refreshProjectInterval: ReturnType<typeof setInterval> = {} as ReturnType<typeof setInterval>;
  selectedJobId: number | null = null;
  selectedJobData$: BehaviorSubject<ClientJobItem> = new BehaviorSubject<ClientJobItem>({} as ClientJobItem);

  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private clientService: ClientService,
    private storageApiService: StorageApiService,
    private authService: AuthService,
    public dialogService: MatDialog,
    private balanceService: BalanceService
  ) {}

  turnOnProjectUpdate() {
    this.refreshProjectInterval = setInterval(() => {
      if (this.authService.isLoggedIn$.getValue()) {
        if (this.selectedJobId != null) {
          this.refreshProjectData(this.selectedJobId);
        }
      }
    }, this.DATA_REFRESH_TIME);
  }

  turnOffProjectUpdate() {
    clearInterval(this.refreshProjectInterval);
  }

  selectJob(jobId: number) {
    this.clearSelectedJobData();
    this.selectedJobId = jobId;
    this.refreshClientJobDetails(jobId);
    this.storageApiService.refreshClientInputResources(jobId, true, true);
    this.selectedJobDataSubscription = this.selectedJobData$.pipe(skip(1)).subscribe((response) => {
      this.storageApiService.refreshClientOutput(jobId, response.finishedFramesCount);
      this.selectedJobDataSubscription?.unsubscribe();
    });
    this.turnOnProjectUpdate();
  }

  clearSelectedJobData() {
    this.turnOffProjectUpdate();
    this.selectedJobData$.next({} as ClientJobItem);
    this.selectedJobId = null;
    this.storageApiService.isBlenderFileExists = true;
    this.storageApiService.clientOutputItems$.next([]);
    this.clientDetailsSubscription?.unsubscribe();
    this.clientOutputResourcesSubscription?.unsubscribe();
    this.clientInputResourcesSubscription?.unsubscribe();
    this.selectedJobDataSubscription?.unsubscribe();
  }

  refreshClientJobDetails(jobId: number) {
    this.clientDetailsSubscription = this.clientService.getClientJobDetails(jobId).subscribe((response) => {
      if (response.success && response.data) {
        this.selectedJobData$.next(response.data);
      } else {
        this.toastrService.error(response.error?.description);
      }
    });
  }

  refreshProjectData(jobId: number) {
    this.clientDetailsSubscription = this.clientService.getClientJobDetails(jobId).subscribe((response) => {
      if (response.success && response.data) {
        const prevStatus = this.selectedJobData$.getValue().status;
        this.selectedJobData$.next(response.data);
        const nextStatus = this.selectedJobData$.getValue().status;
        if (
          this.selectedJobData$.getValue().status == JOB_STATUS.RENDERING ||
          this.selectedJobData$.getValue().status == JOB_STATUS.ANALYZE_IN_PROGRESS ||
          prevStatus !== nextStatus
        ) {
          this.storageApiService.refreshClientOutput(jobId, response.data.finishedFramesCount);
        }
      }
    });
  }

  goBackToPrevPath() {
    let path = this.storageApiService.path;
    if (path.length == 0) {
      this.clientService.searchQuery = this.clientService.prevSearchQuery;
      this.router.navigateByUrl(`/projects`);
    } else {
      path = path.substring(0, path.lastIndexOf('/', path.length - 2) + 1);
      this.storageApiService.path = path;
      if (this.storageApiService.path.length > 0) {
        this.router.navigate([`/projects`, this.selectedJobId], {
          queryParams: { path: path },
          queryParamsHandling: 'merge'
        });
      } else {
        this.router.navigateByUrl(`/projects/${this.selectedJobId}`);
      }
    }
  }

  calculateCost(jobId: number) {
    this.startRendering(jobId);
  }

  analyzeBalance(jobData: ClientJobItem | ClientJobListWithDetails) {
    const balance: number = this.balanceService.balance$.getValue() || 0;
    const analysisCost: number = jobData.analysisCost || 0;
    const initialJobCost: number = jobData.jobCost || 0;
    const amountSpent: number = jobData.amountSpent || 0;
    const jobCost: number = initialJobCost - amountSpent;
    if (balance !== 0 && balance >= jobCost) {
      this.startRendering(jobData.id);
    } else if (balance !== 0 && balance >= analysisCost && balance < jobCost) {
      const estimatedCost = jobCost - balance;
      const message = `Your account balance is not sufficient to complete rendering,
        you need to add <span>${estimatedCost.toFixed(3)}</span> 
        coins to your balance to complete rendering`;

      this.dialogService.open(BalanceWarningModalComponent, {
        panelClass: 'dialog-overlay-pane',
        data: message
      });

      this.startRendering(jobData.id);
    } else {
      const estimatedCost = jobCost - balance;
      const message = `Your balance is <span>${balance.toFixed(3)}</span>, 
      however you need <span>${jobCost.toFixed(3)}</span> coins to complete the rendering job.
      Please top-up your account balance with <span>${estimatedCost.toFixed(3)}</span> 
      coins and click on the link to continue rendering`;

      this.dialogService.open(BalanceWarningModalComponent, {
        panelClass: 'dialog-overlay-pane',
        data: message
      });
    }
  }

  startRendering(jobId: number) {
    this.clientService.startClientJob(jobId).subscribe({
      next: (response) => {
        if (response.success) {
          this.clientService.refreshClientJobList();
          this.refreshProjectData(jobId);
        } else {
          this.toastrService.error(response.error?.description);
        }
      },
      error: (error) => {
        this.toastrService.error(error.message);
      }
    });
  }

  pauseRendering(jobId: number) {
    this.clientService.pauseRendering(jobId).subscribe({
      next: (response) => {
        if (response.success) {
          this.clientService.refreshClientJobList();
          this.refreshProjectData(jobId);
        } else {
          this.toastrService.error(response.error?.description);
        }
      },
      error: (error) => {
        this.toastrService.error(error.message);
      }
    });
  }

  showResult(jobId: number) {
    this.clientService.prevSearchQuery = this.clientService.searchQuery;
    this.clientService.searchQuery = '';
    this.storageApiService.isRenderResultsSectionOpen = true;
    this.router.navigateByUrl(`/projects/${jobId}`);
  }

  uploadFiles(jobId: number, files: NgxFileDropEntry[], isNewProject: boolean): void {
    //let filesCount = files.length;
    let createdFolders: string[] = [];
    this.storageApiService.getUploadCreds(jobId).subscribe({
      next: (response) => {
        if (response.success && response.data) {
          for (const droppedFile of files) {
            const relativePath = droppedFile.relativePath;
            console.log(droppedFile);
            const isFolder = relativePath.includes('/');
            const itemName =
              relativePath.indexOf('/') == -1 ? relativePath : relativePath.substring(0, relativePath.indexOf('/'));
            if (this.storageApiService.isFileNameExist(itemName)) {
              //filesCount--;
              const resourceType = droppedFile.fileEntry.isDirectory || isFolder ? 'Folder' : 'File';
              this.toastrService.error(`${resourceType} with the name '"${itemName}"' already exists`);
            } else if (droppedFile.fileEntry.isFile && this.storageApiService.isFileNameInprogress(jobId, itemName)) {
              //filesCount--;
              this.toastrService.error(`File with the name '"${itemName}"' is loading`);
            } else {
              if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                if (isFolder) {
                  const folderPath = relativePath.substring(0, relativePath.lastIndexOf('/'));
                  if (!createdFolders.includes(folderPath)) {
                    createdFolders.push(folderPath);
                    const foldersArray = folderPath.split('/');
                    let path = '';
                    for (let i = 0; i < foldersArray.length; i++) {
                      path = path + foldersArray[i] + '/';
                      this.storageApiService.uploadFile(jobId, response.data!, path, null);
                    }
                  }
                }
                fileEntry.file((file: File) => {
                  this.storageApiService.uploadFile(jobId, response.data!, relativePath, file).finally(() => {
                    //filesCount--;
                    //if (filesCount === 0) {
                    this.refreshInputAfterUpload(jobId, isNewProject);
                    //}
                  });
                });
              } else {
                this.storageApiService.uploadFile(jobId, response.data!, droppedFile.relativePath, null).finally(() => {
                  // filesCount--;
                  // if (filesCount === 0) {
                  this.refreshInputAfterUpload(jobId, isNewProject);
                  // }
                });
              }
            }
          }
        } else {
          this.toastrService.error(response.error?.description);
        }
      },
      error: (error) => {
        this.toastrService.error(error.message);
      }
    });
  }

  refreshInputAfterUpload(jobId: number, isNewProject: boolean) {
    if (this.selectedJobId) {
      this.storageApiService.refreshClientInputResources(this.selectedJobId, true);
    }
    if (!isNewProject) {
      this.clientService.fileChangesRequest(jobId).subscribe();
    }
  }
}
