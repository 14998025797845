import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { SHOW_LOADER } from '../interceptors/loader.interceptor';

import { environment } from '../../../environments/environment';
import {
  Response,
  AuthTokens,
  LoginClient,
  RegisterClient,
  ProfileInfo,
  ChangePassword,
  ForgotPassword,
  ResetPassword
} from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  profileInfo$: BehaviorSubject<ProfileInfo> = new BehaviorSubject<ProfileInfo>({} as ProfileInfo);

  set accessToken(credentials: string) {
    localStorage.setItem('accessToken', credentials);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ? localStorage.getItem('accessToken')!.toString() : '';
  }

  set refreshToken(credentials: string) {
    localStorage.setItem('refreshToken', credentials);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken') ? localStorage.getItem('refreshToken')!.toString() : '';
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private socialAuthService: SocialAuthService,
    private toastrService: ToastrService
  ) {
    if (this.refreshToken) {
      this.isLoggedIn$.next(true);
    }
    this.socialAuthService.authState.subscribe({
      next: (socialUser: SocialUser) => {
        this.signInWithGoogleRequest(socialUser.idToken).subscribe({
          next: (response) => {
            if (response.success) {
              this.accessToken = response.data!.token;
              this.refreshToken = response.data!.refreshToken;
              this.isLoggedIn$.next(true);
              this.router.navigateByUrl('projects');
            } else {
              this.toastrService.error(response.error?.description);
            }
          }
        });
      },
      error: (err) => {
        console.log('Error while signIn with Google:', err);
        this.toastrService.error(err.message);
      }
    });
  }

  getProfileInfoData() {
    this.getProfileInfo().subscribe({
      next: (response) => {
        if (response.success && response.data) {
          this.profileInfo$.next(response.data);
        } else {
          this.toastrService.error(response.error?.description);
        }
      }
    });
  }

  signInWithGoogleRequest(googleIdToken: string): Observable<Response<AuthTokens>> {
    return this.http.post<Response<AuthTokens>>(`${environment.baseUrl}/auth/login/google`, { googleIdToken });
  }

  registerClient(clientData: RegisterClient): Observable<Response<AuthTokens>> {
    return this.http.post<Response<AuthTokens>>(`${environment.baseUrl}/client`, clientData);
  }

  loginClient(clientData: LoginClient): Observable<Response<AuthTokens>> {
    return this.http.post<Response<AuthTokens>>(`${environment.baseUrl}/auth/login`, clientData);
  }

  changePassword(clientData: ChangePassword): Observable<Response<any>> {
    return this.http.patch<Response<any>>(`${environment.baseUrl}/client/password`, clientData, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.accessToken
      })
    });
  }

  updateProfile(clientData: ProfileInfo): Observable<Response<ProfileInfo>> {
    return this.http.patch<Response<ProfileInfo>>(`${environment.baseUrl}/client`, clientData, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.accessToken
      })
    });
  }

  updateAvatar(avatarFile: File): Observable<Response<ProfileInfo>> {
    const formData = new FormData();
    formData.append('avatar', avatarFile);

    return this.http.put<Response<ProfileInfo>>(`${environment.baseUrl}/client/avatar`, formData, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.accessToken
      })
    });
  }

  forgotPassword(clientData: ForgotPassword) {
    return this.http.post<Response<any>>(`${environment.baseUrl}/client/password/reset?email=${clientData.email}`, {});
  }

  resetPassword(clientData: ResetPassword) {
    return this.http.put<Response<any>>(`${environment.baseUrl}/client/password`, clientData);
  }

  getProfileInfo(): Observable<Response<ProfileInfo>> {
    return this.http.get<Response<ProfileInfo>>(`${environment.baseUrl}/client`, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.accessToken
      })
    });
  }

  refreshTokenRequest(): Observable<Response<AuthTokens>> {
    const refreshToken: string = this.refreshToken;
    console.log('Make refresh token request...');
    return this.http.post<Response<AuthTokens>>(
      `${environment.baseUrl}/auth/token/refresh`,
      { refreshToken },
      { context: new HttpContext().set(SHOW_LOADER, false) }
    );
  }

  private signOut(): void {
    this.socialAuthService.signOut();
  }

  logout(withRedirectOnHomePage: boolean = false) {
    this.isLoggedIn$.next(false);
    this.signOut();
    this.accessToken = '';
    this.refreshToken = '';
    if (!withRedirectOnHomePage) {
      this.router.navigateByUrl('login');
    }
  }
}
