<header class="header">
  <div class="container">
    <div class="header-wrapper">
      <span class="header-wrapper__menu-icon icon-Menu hide-desktop hide-tablet" (click)="toggleMobileMenu()"></span>
      <a class="header-wrapper__title hide-tablet hide-desktop" href="https://www.gaimin.cloud/" target="_blank"
        ><img src="./assets/imgs/logo.png" alt=""
      /></a>
      <div class="header-nav" [ngClass]="{ active: isMobileMenuOpen | async }">
        <span class="close-btn icon-Close" (click)="toggleMobileMenu()"></span>
        <div class="header-nav__wrapper">
          <a class="header-nav__title" href="https://www.gaimin.cloud/" target="_blank"
            ><img src="./assets/imgs/logo.png" alt=""
          /></a>
          <ul>
            <li>
              <a href="" routerLinkActive="active" [routerLink]="'/projects'" (click)="closeMobileMenu()">
                <span class="title">Projects</span>
                <span class="icon icon-Menu-Projects"></span>
              </a>
            </li>
            <li>
              <a href="" routerLinkActive="active" [routerLink]="'/wallet'" (click)="closeMobileMenu()">
                <span class="title">Wallet</span>
                <span class="icon icon-Menu-Coins"></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="header-profile">
        <div class="header-profile__balance hide-mobile" *ngIf="(balanceService.balance$ | async) != null">
          <div class="header-profile__balance-title">Balance</div>
          <div class="header-profile__balance-value">
            <span>{{ balanceService.balance$ | async | number : '1.0-2' }}</span>
            <span>{{ (balanceService.balance$ | async) !== 1 ? 'Coins' : 'Coin' }}</span>
            <span class="icon icon-Dashboard"></span>
          </div>
        </div>
        <div
          class="header-profile__avatar"
          [ngClass]="{ active: isProfileMenuOpen | async }"
          (click)="openProfileMenu()"
          appClickedOutside
          (clickOutside)="clickedOutside()">
          <span
            *ngIf="authService.profileInfo$.getValue().avatarUrl"
            class="header-profile__avatar-image"
            [ngStyle]="{ 'background-image': 'url(' + authService.profileInfo$.getValue().avatarUrl! + ')' }"></span>
          <span
            *ngIf="!authService.profileInfo$.getValue().avatarUrl"
            class="header-profile__avatar-icon icon-Empty-User"></span>
          <span class="icon-arrow icon-Arrow-Bottom hide-mobile"></span>
          <ul>
            <li (click)="openProfileSettingsPopup($event)"><span class="name">Profile Settings</span></li>
            <li (click)="logout()"><span class="name">Logout</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>
