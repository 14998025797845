<div class="socials">
  <h2 class="socials__header">Sign in with</h2>
  <div class="socials__logins">
    <div class="socials__logins-items">
      <asl-google-signin-button
        width="200px"
        shape="rectangular"
        theme="filled_black"
        text="signin_with"
        size="medium"
        logo_alignment="left"
        locale="en"
        type="standard"></asl-google-signin-button>
      <p>Google</p>
      <img src="./assets/imgs/google-icon.svg" alt="google" />
    </div>
    <div class="socials__logins-items">
      <p>Apple</p>
      <img src="./assets/imgs/apple-icon.svg" alt="apple" />
    </div>
  </div>
</div>
