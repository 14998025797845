import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ChangePasswordModalComponent } from '../change-password-modal/change-password-modal.component';
import { AuthService } from '../../../services/auth.service';
import { ClientService } from '../../../services/client.service';
import { ForgotPassword, Button } from '../../../interfaces';
import { BUTTON_SIZE, BUTTON_THEME } from '../../../enums';

@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.scss']
})
export class ForgotPasswordModalComponent implements OnInit {
  isMessageSent: boolean = false;
  profileImage: string | any = '';
  emailMinLength = 3;
  emailMaxLength = 200;
  usernameMinLength = 2;
  usernameMaxLength = 100;

  form: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.minLength(this.emailMinLength),
      Validators.maxLength(this.emailMaxLength),
      Validators.email
    ])
  });

  readonly submitBtn: Button = {
    name: 'Reset Password',
    size: BUTTON_SIZE.LG
  };

  readonly closeBtn: Button = {
    name: 'Close',
    size: BUTTON_SIZE.LG
  };

  constructor(
    private authService: AuthService,
    private toastrService: ToastrService,
    public dialogService: MatDialog,
    public dialogRef: MatDialogRef<ForgotPasswordModalComponent>
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    if (this.form.valid) {
      const clientData: ForgotPassword = {
        email: this.form.get('email')?.value
      };
      this.authService.forgotPassword(clientData).subscribe({
        next: (response) => {
          if (response.success) {
            this.isMessageSent = true;
          } else {
            this.toastrService.error(response.error?.description);
          }
        },
        error: (error) => {
          this.toastrService.error(error.message);
        }
      });
    }
  }

  onClose() {
    this.dialogRef.close();
  }
}
