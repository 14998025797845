import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-balance-warning-modal',
  templateUrl: './balance-warning-modal.component.html',
  styleUrls: ['./balance-warning-modal.component.scss']
})
export class BalanceWarningModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<BalanceWarningModalComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string
  ) {}

  ngOnInit(): void {}
}
