<div class="table-section">
  <div class="table-section__header with-title">
    <div class="table-section__header-buttons">
      <app-button [buttonData]="backBtn" (click)="goBack()"></app-button>
    </div>
    <h3 class="table-section__header-title">Render Result</h3>
  </div>
  <div class="table-section__body">
    <div class="render-results">
      <div
        class="render-results__item"
        *ngFor="
          let item of storageApiService.clientOutputItems$.getValue();
          trackBy: trackByData;
          let itemIndex = index
        ">
        <img [src]="item.thumbnailLink" alt="" (click)="openImagePopup(itemIndex)" />
      </div>
    </div>
  </div>
</div>
