import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AdditionalService } from '../../services/additional.service';
import { ClientService } from '../../services/client.service';
import { LoaderService } from '../../services/loader.service';
import { StorageApiService } from '../../services/storage-api.service';
import { CreateJobModalComponent } from '../modals/create-job-modal/create-job-modal.component';
import { EditFileNameModalComponent } from '../modals/edit-file-name-modal/edit-file-name-modal.component';
import { ClientJob, ClientJobListWithDetails, Warning, GroupedWarnings, Button } from '../../interfaces';
import { JOB_STATUS } from '../../enums';

@Component({
  selector: 'app-projects-table',
  templateUrl: './projects-table.component.html',
  styleUrls: ['./projects-table.component.scss']
})
export class ProjectsTableComponent implements OnInit, OnDestroy {
  tableData: ClientJobListWithDetails[] = [];
  searchColumns: string[] = ['name'];

  readonly createProjectBtn: Button = {
    name: 'Create Project',
    iconRight: 'icon-plus'
  };
  private sortParams: Sort = { active: 'modified', direction: 'desc' };
  private clientJobsSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    public dialogService: MatDialog,
    private toastrService: ToastrService,
    private changeDetector: ChangeDetectorRef,
    public storageApiService: StorageApiService,
    public loaderService: LoaderService,
    public clientService: ClientService,
    public additionalService: AdditionalService
  ) {}

  ngOnInit(): void {
    this.sortParams = this.clientService.projectSortParams;
    this.clientJobsSubscription = this.clientService.clientJobs$.subscribe((response: ClientJobListWithDetails[]) => {
      this.tableData = response;
      this.sortData(this.sortParams);
    });
  }

  navigateTo(item: ClientJob) {
    this.clientService.prevSearchQuery = this.clientService.searchQuery;
    this.clientService.searchQuery = '';
    this.router.navigateByUrl(`/projects/${item.id}`);
  }

  openCreateProjectPopup() {
    this.dialogService.open(CreateJobModalComponent, { panelClass: 'dialog-overlay-pane' });
  }

  toggleMenu(job: ClientJob, event: any) {
    event.stopPropagation();
    this.clickedOutside();
    let itemIndex = this.tableData.findIndex((item: ClientJob) => item.id == job.id);
    this.tableData[itemIndex].isMenuOpen = !this.tableData[itemIndex].isMenuOpen;
  }

  clickedOutside() {
    this.tableData.forEach((item: ClientJob) => {
      item.isMenuOpen = false;
    });
  }

  removeItem(item: ClientJob, event: any) {
    event.stopPropagation();
    this.clickedOutside();
    if (item.status !== JOB_STATUS.ANALYZE_IN_PROGRESS && item.status !== JOB_STATUS.RENDERING) {
      this.clientService.removeJob(item.id).subscribe({
        next: (response) => {
          if (response.success) {
            let clientJobs = this.clientService.clientJobs$.getValue();
            clientJobs = clientJobs.filter(function (job) {
              return job.id !== item.id;
            });
            this.clientService.clientJobs$.next(clientJobs);
          } else {
            this.toastrService.error(response.error?.description);
          }
        },
        error: (error) => {
          this.toastrService.error(error.message);
        }
      });
    } else {
      const normalizedStatus: string = this.additionalService.normalizeStatus(item.status);
      this.toastrService.warning(`The project status is "${normalizedStatus}"`, 'The project could not be removed');
    }
  }

  openRenameProjectPopup(item: ClientJob) {
    this.clickedOutside();
    if (item.status === JOB_STATUS.NEW || item.status === JOB_STATUS.ANALYZED) {
      this.dialogService.open(EditFileNameModalComponent, {
        panelClass: 'dialog-overlay-pane',
        data: item
      });
    } else {
      const normalizedStatus: string = this.additionalService.normalizeStatus(item.status);
      this.toastrService.warning(
        `The project status is "${normalizedStatus}"`,
        'The project name could not be changed'
      );
    }
  }

  groupWarnings(warnings: Warning[]) {
    let convertedArray: GroupedWarnings = {} as GroupedWarnings;
    warnings.forEach((item) => {
      convertedArray[item.title] = convertedArray[item.title]
        ? convertedArray[item.title] + '\n\n' + item.message
        : '\n\n' + item.message;
    });
    return convertedArray;
  }

  trackByData(index: number, item: any) {
    return index;
  }

  sortData(sort: Sort | any) {
    this.sortParams = sort;

    this.clientService.projectSortParams = this.sortParams;

    if (!sort.active || sort.direction === '') {
      return;
    }

    this.tableData = this.additionalService.sortData(sort, this.tableData);

    if (this.clientService.searchQuery !== '') {
      const tempSearchVal = this.clientService.searchQuery;
      this.clientService.searchQuery = '';
      this.changeDetector.detectChanges();
      this.clientService.searchQuery = tempSearchVal;
    }
  }

  ngOnDestroy(): void {
    this.dialogService.closeAll();
    this.clientJobsSubscription?.unsubscribe();
  }
}
