<div class="create-job">
  <div class="header">
    <h1>Edit {{ clientService.isProjectsPage ? 'project' : 'file' }} name</h1>
    <span class="close-icon icon-Close-Bold" mat-dialog-close></span>
  </div>
  <form [formGroup]="form" class="form form-modal" autocomplete="off">
    <div class="form__field-group">
      <input
        [ngClass]="form.invalid && form.get('fileName')?.dirty ? 'ng-invalid' : ''"
        formControlName="fileName"
        type="text"
        placeholder="Enter new {{ clientService.isProjectsPage ? 'project' : 'file' }} name"
        id="fileName" />
      <span *ngIf="form.get('fileName')?.hasError('required') && form.get('fileName')?.dirty" class="form__error">
        {{ clientService.isProjectsPage ? 'Project' : 'File' }} name should be present
      </span>
      <span *ngIf="form.get('fileName')?.hasError('maxlength') && form.get('fileName')?.dirty" class="form__error">
        {{ clientService.isProjectsPage ? 'Project' : 'File' }} name should not exceed 100 characters
      </span>
      <span *ngIf="form.get('fileName')?.hasError('pattern') && form.get('fileName')?.dirty" class="form__error">
        {{ clientService.isProjectsPage ? 'Project' : 'File' }} name should not contain a dot
      </span>
      <span *ngIf="form.get('fileName')?.hasError('notEqual') && form.get('fileName')?.dirty" class="form__error"
        >{{ clientService.isProjectsPage ? 'Project' : 'File' }} with this name already exists</span
      >
    </div>
    <app-button
      [attr.disabled]="form.invalid"
      [disabled]="form.invalid"
      (click)="editName()"
      [buttonData]="submitBtn"></app-button>
  </form>
</div>
