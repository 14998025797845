import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Button } from '../../../interfaces';
import { BUTTON_SIZE } from '../../../enums';

@Component({
  selector: 'app-checkout-status-modal',
  templateUrl: './checkout-status-modal.component.html',
  styleUrls: ['./checkout-status-modal.component.scss']
})
export class CheckoutStatusModalComponent implements OnInit, OnDestroy {
  paymentStatusTitle: string = '';
  paymentStatusMessage: string = '';
  paymentStatusType: string = '';

  readonly submitBtn: Button = {
    name: 'Close',
    size: BUTTON_SIZE.LG
  };

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<CheckoutStatusModalComponent>,
    @Inject(MAT_DIALOG_DATA) public paymentIntent: any
  ) {}

  ngOnInit(): void {
    switch (this.paymentIntent?.status) {
      case 'succeeded':
        this.paymentStatusType = 'success';
        this.paymentStatusTitle = 'Payment Success';
        this.paymentStatusMessage = 'Your account balance has been successfully topped up';
        this.submitBtn.name = 'Continue';
        break;
      case 'processing':
        this.paymentStatusType = 'success';
        this.paymentStatusTitle = 'Payment Processing';
        this.paymentStatusMessage = 'Your payment is still processing';
        break;
      case 'requires_payment_method':
        this.paymentStatusType = 'error';
        this.paymentStatusTitle = 'Payment Failed';
        this.paymentStatusMessage =
          'Your attempt to top up your account was unsuccessful. You can try again or write to <a href="mailto:technical_support@gaimin.io">support</a>';
        break;
      default:
        this.paymentStatusType = 'error';
        this.paymentStatusTitle = 'Something get wrong';
        this.paymentStatusMessage =
          'Unknow error appered. Please get in touch with <a href="mailto:technical_support@gaimin.io">support</a>';
        break;
    }
  }

  openProjectsPage() {
    this.router.navigateByUrl('projects');
  }

  ngOnDestroy(): void {}
}
