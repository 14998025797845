import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpEvent,
  HttpContextToken,
  HttpErrorResponse
} from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { Observable, catchError, throwError } from 'rxjs';
import { LoaderService } from '../services/loader.service';

export const SHOW_LOADER = new HttpContextToken(() => true);

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private loaderService: LoaderService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!navigator.onLine) {
      return throwError(() => new Error('Please check your internet connection and try again.'));
    }

    Promise.resolve(null).then(() => {
      if (request.context.get(SHOW_LOADER)) {
        this.totalRequests++;
        this.loaderService.showLoader();
      }
    });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (this.totalRequests > 0) {
          this.totalRequests--;
        }
        if (this.totalRequests === 0) {
          this.loaderService.hideLoader();
        }
        return throwError(() => new Error(error.message));
      }),
      finalize(() => {
        if (request.context.get(SHOW_LOADER)) {
          if (this.totalRequests > 0) {
            this.totalRequests--;
          }
          if (this.totalRequests === 0) {
            this.loaderService.hideLoader();
          }
        }
      })
    );
  }
}
