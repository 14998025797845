<ng-container *ngFor="let item of storageApiService.uploadProgressData$ | async | keyvalue; trackBy: trackByData">
  <div class="upload-progress-item" *ngIf="!item.value.isHidden">
    <span class="close-icon icon-Close-Bold" (click)="removeAlert(item.key)"></span>
    <div class="upload-progress-item-content">
      <div class="upload-progress-item-content-data">
        <div class="name">{{ item.value.name }}</div>
        <div class="progress" *ngIf="item.value.progress">{{ item.value.progress.value }}%</div>
        <div class="progress" *ngIf="item.value.loading">{{ item.value.loading.value }} MB</div>
      </div>
      <app-progress-bar *ngIf="item.value.progress" [percent]="item.value.progress.value"></app-progress-bar>
      <div
        class="loading-animation-bar"
        *ngIf="item.value.loading"
        [ngClass]="{ finished: item.value.loading.isCompleted, canceled: item.value.canceled }"></div>
    </div>
    <div
      class="upload-progress-item-button"
      [ngClass]="{ finished: item.value.progress?.value == 100 || item.value.loading?.isCompleted }">
      <span
        *ngIf="
          (item.value.progress && item.value.progress.value < 100) ||
          (item.value.loading && !item.value.loading.isCompleted)
        "
        class="icon-Close-Bold"
        (click)="cancelUpload(item.key)"></span>
      <span *ngIf="item.value.progress?.value == 100 || item.value.loading?.isCompleted" class="icon-Checked"></span>
    </div>
  </div>
</ng-container>
