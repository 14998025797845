<div class="profile-settings">
  <div class="header">
    <h1>Profile Settings</h1>
    <span class="close-icon icon-Close-Bold" mat-dialog-close></span>
  </div>
  <form [formGroup]="form" class="form form-modal" autocomplete="off">
    <div class="form__field-group">
      <label class="hide-mobile">User Photo</label>
      <div class="profilephoto">
        <span
          *ngIf="profileImage"
          class="profilephoto-image"
          [ngStyle]="{ 'background-image': 'url(' + profileImage + ')' }"></span>
        <span class="profilephoto-icon-empty icon-Empty-User" *ngIf="!profileImage"></span>
        <span class="profilephoto-icon-upload icon-Upload-Photo" (click)="fileInput.click()"></span>
        <input class="profilephoto-input" #fileInput (change)="uploadPhoto($event)" type="file" />
      </div>
    </div>
    <div class="form__field-group">
      <label for="username">User Name</label>
      <input id="username" formControlName="username" type="text" placeholder="Enter user name" />

      <ng-container *ngIf="form.get('username')?.invalid && form.get('username')?.dirty">
        <span *ngIf="form.get('username')?.hasError('required')" class="form__error">Username should be present </span>

        <ng-container
          *ngIf="
            form.get('username')?.hasError('minlength') || form.get('username')?.hasError('maxlength');
            else username_pattern
          ">
          <span class="form__error">
            Username should have <mark>{{ usernameMinLength }} - {{ usernameMaxLength }}</mark> characters. You
            currently typed
            <mark>{{ form.get('username')?.value.length }}</mark>
          </span>
        </ng-container>

        <ng-template #username_pattern>
          <ng-container>
            <span class="form__error" *ngIf="form.get('username')?.hasError('pattern')"
              >Username cannot contain whitespace characters</span
            >
          </ng-container>
        </ng-template>
      </ng-container>
    </div>
    <div class="form__field-group">
      <label for="email">Email</label>
      <input id="email" formControlName="email" type="text" placeholder="Enter email" />

      <ng-container *ngIf="form.get('email')?.invalid && form.get('email')?.dirty">
        <span *ngIf="form.get('email')?.hasError('required')" class="form__error">Email should be present </span>

        <ng-container
          *ngIf="
            form.get('email')?.hasError('minlength') || form.get('email')?.hasError('maxlength');
            else email_pattern
          ">
          <span class="form__error">
            Email should have
            <mark>{{ emailMinLength }} - {{ emailMaxLength }}</mark>
            characters. You currently typed
            <mark>{{ form.get('email')?.value.length }}</mark>
          </span>
        </ng-container>

        <ng-template #email_pattern>
          <ng-container *ngIf="form.get('email')?.hasError('email')">
            <span class="form__error">Valid email address required</span>
          </ng-container>
        </ng-template>
      </ng-container>
    </div>
    <div class="form__field-group">
      <app-button
        class="change-password-btn"
        (click)="openChangePasswordPopup()"
        [buttonData]="changePasswordBtn"></app-button>
    </div>

    <app-button
      [attr.disabled]="form.invalid"
      [disabled]="form.invalid"
      (click)="onSubmit()"
      [buttonData]="submitBtn"></app-button>
  </form>
</div>
