import { Component, Input, OnInit } from '@angular/core';
import { Button } from '../../interfaces';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() buttonData!: Button;
  @Input() disabled: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
