<div class="change-password">
  <div class="header">
    <h1>Create New Password</h1>
    <h2>Your new password must be different from previous used passwords.</h2>
    <span class="close-icon icon-Close-Bold" mat-dialog-close></span>
  </div>
  <form [formGroup]="form" class="form form-modal" autocomplete="off">
    <div class="form__field-group">
      <label>Password</label>
      <div class="password-field">
        <input
          formControlName="password"
          [type]="showNewPassword ? 'text' : 'password'"
          placeholder="Write new password" />
        <span
          (click)="togglePassword('new')"
          class="icon"
          [ngClass]="{
            'icon-Hide-Password': !showNewPassword,
            'icon-Show-Password': showNewPassword
          }"></span>
      </div>
      <ng-container *ngIf="form.get('password')?.invalid && form.get('password')?.dirty">
        <span *ngIf="form.get('password')?.hasError('required')" class="form__error"> Password should be present </span>

        <ng-container
          *ngIf="
            form.get('password')?.hasError('minlength') || form.get('password')?.hasError('maxlength');
            else password_pattern
          ">
          <span class="form__error">
            Password should have <mark>{{ passwordMinLength }} - {{ passwordMaxLength }}</mark> characters. You
            currently typed
            <mark>{{ form.get('password')?.value.length }}</mark>
          </span>
        </ng-container>

        <ng-template #password_pattern>
          <ng-container *ngIf="form.get('password')?.hasError('pattern'); else password_complexity">
            <span class="form__error">Password can have latin characters, digits and symbols</span>
          </ng-container>
        </ng-template>

        <ng-template #password_complexity>
          <ng-container *ngIf="form.get('password')?.hasError('complexity')">
            <span class="form__error"
              >Password should contain at least 1 lowercase character, 1 uppercase character, 1 digit</span
            >
          </ng-container>
        </ng-template>
      </ng-container>
    </div>

    <div class="form__field-group">
      <label>Confirm Password</label>
      <div class="password-field">
        <input
          formControlName="confirmPassword"
          [type]="showConfirmPassword ? 'text' : 'password'"
          [ngClass]="{ invalid: form.hasError('notEqual') }"
          placeholder="Repeat new Password" />
        <span
          (click)="togglePassword('confirm')"
          class="icon"
          [ngClass]="{
            'icon-Hide-Password': !showConfirmPassword,
            'icon-Show-Password': showConfirmPassword
          }"></span>
      </div>
      <ng-container
        *ngIf="
          (form.get('confirmPassword')?.invalid || form.hasError('notEqual')) && form.get('confirmPassword')?.dirty
        ">
        <span
          *ngIf="form.get('confirmPassword')?.hasError('required') && !form.hasError('notEqual')"
          class="form__error">
          Password should be present
        </span>
        <span *ngIf="form.hasError('notEqual')" class="form__error"> Passwords do not match </span>
      </ng-container>
    </div>

    <app-button
      [attr.disabled]="form.invalid"
      [disabled]="form.invalid"
      (click)="onSubmit()"
      [buttonData]="submitBtn"></app-button>
  </form>
</div>
