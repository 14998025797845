import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClientService } from '../../shared/services/client.service';

@Component({
  selector: 'app-projects-page',
  templateUrl: './projects-page.component.html',
  styleUrls: ['./projects-page.component.scss']
})
export class ProjectsPageComponent implements OnInit, OnDestroy {
  constructor(public clientService: ClientService) {}

  ngOnInit(): void {
    this.clientService.isProjectsPage = true;
    this.clientService.clientJobs$.next([]);
    this.clientService.refreshClientJobList();
    this.clientService.turnOnJobListDetailsUpdater();
  }

  ngOnDestroy(): void {
    this.clientService.turnOffJobListDetailsUpdater();
  }
}
