import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpContext } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { Response } from '../interfaces';
import { SHOW_LOADER } from '../interceptors/loader.interceptor';

@Injectable({
  providedIn: 'root'
})
export class BalanceService {
  private readonly REFRESH_BALANCE_TIME: number = 1000 * 30;
  balance$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);

  constructor(private http: HttpClient, private authService: AuthService) {
    setInterval(() => {
      if (this.authService.isLoggedIn$.getValue()) {
        this.refreshBalance();
      }
    }, this.REFRESH_BALANCE_TIME);
  }

  refreshBalance() {
    this.getClientBalance().subscribe({
      next: (response) => {
        if (response.success && response.data != null) {
          this.balance$.next(response.data);
        }
      }
    });
  }

  getClientBalance(): Observable<Response<number>> {
    return this.http.get<Response<number>>(`${environment.baseUrl}/client/balance`, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.accessToken
      }),
      context: new HttpContext().set(SHOW_LOADER, false)
    });
  }
}
