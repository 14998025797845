import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { StorageApiService } from '../../../services/storage-api.service';
import { ClientService } from '../../../services/client.service';
import { ProjectService } from '../../../services/project.service';
import { Button, ResourceItem, ClientJob } from '../../../interfaces';
import { BUTTON_SIZE } from '../../../enums';

@Component({
  selector: 'app-edit-file-name-modal',
  templateUrl: './edit-file-name-modal.component.html',
  styleUrls: ['./edit-file-name-modal.component.scss']
})
export class EditFileNameModalComponent implements OnInit {
  form: FormGroup = new FormGroup({
    fileName: new FormControl(this.removeFileExtension(this.resourceItem?.name, this.resourceItem?.extension), [
      Validators.required,
      Validators.maxLength(100),
      this.getPatternValidator(),
      this.checkUniqueName()
    ])
  });

  readonly submitBtn: Button = {
    name: 'Edit',
    size: BUTTON_SIZE.LG
  };

  constructor(
    private storageApiService: StorageApiService,
    public clientService: ClientService,
    private projectService: ProjectService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<EditFileNameModalComponent>,
    @Inject(MAT_DIALOG_DATA) public resourceItem: ClientJob | ResourceItem | any
  ) {}

  ngOnInit(): void {}

  editName() {
    if (this.form.valid) {
      if (this.clientService.isProjectsPage) {
        this.clientService.renameProject(this.resourceItem.id, this.form.get('fileName')?.value).subscribe({
          next: (response) => {
            if (response.success) {
              let clientJobs = this.clientService.clientJobs$.getValue();
              clientJobs = clientJobs.map((job) => {
                if (job.id == this.resourceItem.id) {
                  job.name = this.form.get('fileName')?.value;
                }
                return job;
              });
              this.clientService.clientJobs$.next(clientJobs);
            } else {
              this.toastrService.error(response.error?.description);
            }
          },
          error: (error) => {
            this.toastrService.error(error.message);
          }
        });
      } else {
        const path = this.resourceItem.path + this.resourceItem.name;
        const extension = this.resourceItem.extension ? '.' + this.resourceItem.extension : '';
        const newResourceName = this.resourceItem.path + this.form.get('fileName')?.value + extension;
        this.storageApiService.renameClientFile(this.projectService.selectedJobId!, path, newResourceName).subscribe({
          next: () => {
            this.storageApiService.refreshClientInputResources(this.projectService.selectedJobId!);
          },
          error: (error) => {
            this.toastrService.error(error.message);
          }
        });
      }
      this.dialogRef.close();
    }
  }

  getPatternValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.clientService.isProjectsPage ? null : Validators.pattern('^[^.]+$')(control);
    };
  }

  checkUniqueName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.clientService.isProjectsPage) {
        const isJobNameExist: boolean = this.clientService.clientJobs$
          .getValue()
          .some((job) => job.name === control.value);
        return !isJobNameExist ? null : { notEqual: true };
      } else {
        const itemsInFolder = this.storageApiService.filterClientResourcesByPath();
        const isFileNameExist: boolean = itemsInFolder.some(
          (file) => this.removeFileExtension(file.name, file.extension) === control.value
        );
        return !isFileNameExist ? null : { notEqual: true };
      }
    };
  }

  removeFileExtension(fileName: string, fileExtension: string) {
    return fileName.replace(new RegExp('.' + fileExtension + '$'), '');
  }
}
