<div class="file-details">
  <div class="header">
    <h1>{{ file.path }}{{ file.name }}</h1>
    <span class="close-icon icon-Close-Bold" mat-dialog-close></span>
  </div>
  <div class="file">
    <div class="file-container">
      <div class="file__icon">
        <ng-container *ngIf="file.extension !== 'blend'; else blenderIcon">
          <span matListItemIcon class="file-type-icon" [ngClass]="file.fileIcon"></span>
        </ng-container>
        <ng-template #blenderIcon>
          <img matListItemIcon class="image-icon" src="./assets/imgs/blender-icon.svg" alt="" height="20" width="24"/>
        </ng-template>
      </div>
      <span class="file__name">
        {{ file.name }}
      </span>
      <span class="file__size">{{ formatBytes(file.size) }}</span>
      <div class="file__metadata">
        <div class="file__metadata-version" *ngIf="mainFileVersion">version: {{ mainFileVersion }}</div>
        <div class="file__metadata-datetime">{{ file.modified | date : 'dd.MM.yy, HH:mm' }}</div>
      </div>
    </div>
  </div>
</div>
