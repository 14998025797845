import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-project-faq-modal',
  templateUrl: './create-project-faq-modal.component.html',
  styleUrls: ['./create-project-faq-modal.component.scss']
})
export class CreateProjectFAQModalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
