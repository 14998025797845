import { Pipe, PipeTransform } from '@angular/core';
import { AdditionalService } from '../../shared/services/additional.service';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  constructor(private additionalService: AdditionalService) {}

  transform(items: any[], columns: string[], searchQuery: string): any[] {
    if (!items) return [];
    if (!searchQuery) return items;

    searchQuery = searchQuery.toLowerCase();

    return items.filter((item) => {
      return columns.some((column) => {
        if (column == 'transaction_type') {
          const transaction_type = this.additionalService.getTransactionName(item.type?.code);
          return transaction_type.toLowerCase().includes(searchQuery);
        }
        if (column == 'transaction_job') {
          return item.job?.name.toLowerCase().includes(searchQuery);
        }
        return item[column].toString().toLowerCase().includes(searchQuery);
      });
    });
  }
}
