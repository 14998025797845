<div class="upload-files">
  <div class="header">
    <h1>Upload Files</h1>
    <span class="close-icon icon-Close-Bold" mat-dialog-close></span>
  </div>
  <div class="drag-and-drop-container">
    <ngx-file-drop [multiple]="true" (onFileDrop)="droppedFiles($event)" class="drag-and-drop-container">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <span class="icon icon-Upload" (click)="openFileSelector()"></span>
        <p>Click to <span (click)="openFileSelector()">upload</span> or drag and drop</p>
      </ng-template>
    </ngx-file-drop>
    <ul *ngIf="files.length > 0">
      <li *ngFor="let file of convertedFiles; let i = index">
        <span
          *ngIf="file.name.split('.').pop() !== 'blend'; else blenderIcon"
          class="file-type-icon"
          [ngClass]="file.type"></span>
        <span class="name">{{ file.name }}</span>
        <span class="close icon-Close-Bold" (click)="removeFile(i, file.name)"></span>
      </li>
    </ul>
    <ng-template #blenderIcon>
      <img src="./assets/imgs/blender-icon.svg" alt="" />
    </ng-template>
  </div>
  <app-button
    [attr.disabled]="files.length == 0"
    [disabled]="files.length == 0"
    (click)="onSubmit()"
    [buttonData]="submitBtn"></app-button>
</div>
