import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders, HttpContext, HttpEvent, HttpEventType } from '@angular/common/http';
import { Observable, BehaviorSubject, tap, takeWhile } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { AuthService } from './auth.service';
import { SHOW_LOADER } from '../interceptors/loader.interceptor';
import {
  ResourceItem,
  Resources,
  OutputItems,
  OutputItem,
  ResourceUploadCreds,
  UploadProgressData,
  Response
} from '../interfaces';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StorageApiService {
  private sendFileAttempt: number = 3;
  path: string = '';
  clientResourcesItems$: BehaviorSubject<ResourceItem[]> = new BehaviorSubject<ResourceItem[]>([]);
  clientOutputItems$: BehaviorSubject<OutputItem[]> = new BehaviorSubject<OutputItem[]>([]);
  clientOutput: Resources = {} as Resources;
  isRenderResultsSectionOpen: boolean = false;
  isBlenderFileExists: boolean = true;
  //selectedJobId: number | null = null;
  uploadProgressData$: BehaviorSubject<UploadProgressData> = new BehaviorSubject<UploadProgressData>({});

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toastrService: ToastrService,
    private ngZone: NgZone
  ) {}

  isFileNameExist(fileName: string): boolean {
    const itemsInFolder = this.filterClientResourcesByPath();
    return itemsInFolder.some((el: any) => {
      return el.name === fileName;
    });
  }

  isFileNameInprogress(jobId: number, fileName: string): boolean {
    const fullPathId = jobId + '/' + this.path + fileName;
    const uploadProgressData: UploadProgressData = this.uploadProgressData$.getValue();
    if (uploadProgressData[fullPathId]) {
      if (uploadProgressData[fullPathId].isHidden) {
        uploadProgressData[fullPathId].isHidden = false;
        this.ngZone.run(() => {
          this.uploadProgressData$.next(uploadProgressData);
        });
      }
      if (uploadProgressData[fullPathId].canceled) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  isJobFileUploadInprogress(jobId: number): boolean {
    const uploadProgressData: UploadProgressData = this.uploadProgressData$.getValue();
    for (const fullPathId in uploadProgressData) {
      if (fullPathId.startsWith(jobId + '/')) {
        const progressData = uploadProgressData[fullPathId];
        if (!progressData.canceled && progressData.progress?.value !== 100) {
          return true;
        }
      }
    }
    return false;
  }

  isFileWithExtensionExist(extension: string): boolean {
    return this.clientResourcesItems$.getValue().some((el: any) => {
      return el.extension === extension;
    });
  }

  refreshClientInputResources(jobId: number, showLoader: boolean = false, clearResources: boolean = false) {
    if (clearResources) {
      this.clientResourcesItems$.next([]);
    }
    this.getClientInputResources(jobId, showLoader).subscribe((response) => {
      if (response.success && response.data) {
        this.clientResourcesItems$.next(response.data);
        this.isBlenderFileExists = this.isFileWithExtensionExist('blend');
      }
    });
  }

  refreshClientOutput(jobId: number, framesCount: number = 0) {
    this.getClientOutputResources(jobId, framesCount).subscribe((response) => {
      if (response.success && response.data) {
        this.clientOutputItems$.next(response.data.items);
      } else {
        this.toastrService.error(response.error?.description);
      }
    });
  }

  filterClientResourcesByPath() {
    return this.clientResourcesItems$.getValue().filter((item) => item.path == this.path);
  }

  downloadClientOutputResources(jobId: number, fileName: string) {
    const archiveName = `GAIMIN_RNDR_${fileName}.zip`;
    const uploadProgressData: UploadProgressData = this.uploadProgressData$.getValue();
    uploadProgressData[archiveName] = {
      name: archiveName,
      canceled: false,
      isHidden: false,
      loading: {
        value: '0',
        isCompleted: false
      }
    };
    this.ngZone.run(() => {
      this.uploadProgressData$.next(uploadProgressData);
    });
    this.getResultsArchive(jobId)
      .pipe(
        takeWhile(() => !this.uploadProgressData$.getValue()[archiveName]?.canceled),
        tap((event: HttpEvent<ArrayBuffer>) => {
          if (event.type === HttpEventType.DownloadProgress && uploadProgressData[archiveName]) {
            uploadProgressData[archiveName].loading!.value = (event.loaded / (1024 * 1024)).toFixed(1);
            this.ngZone.run(() => {
              this.uploadProgressData$.next(uploadProgressData);
            });
          } else if (event.type === HttpEventType.Response && event.body) {
            const blob = new Blob([event.body], { type: 'application/octet-stream' });
            saveAs(blob, archiveName);
            if (uploadProgressData[archiveName]) {
              uploadProgressData[archiveName].loading!.isCompleted = true;
              this.ngZone.run(() => {
                this.uploadProgressData$.next(uploadProgressData);
              });
            }
          }
        })
      )
      .subscribe();
  }

  downloadClientOutputImage(outputItemPath: string) {
    window.open(outputItemPath);
  }

  getClientInputResources(jobId: number, showLoader: boolean = false): Observable<Response<ResourceItem[]>> {
    return this.http.get<Response<ResourceItem[]>>(`${environment.baseUrl}/storage/job/${jobId}/input`, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.accessToken
      }),
      context: new HttpContext().set(SHOW_LOADER, showLoader)
    });
  }

  getClientOutputResources(jobId: number, framesCount: number = 0): Observable<Response<OutputItems>> {
    framesCount = framesCount == 0 ? 1 : framesCount;
    return this.http.get<Response<OutputItems>>(
      `${environment.baseUrl}/storage/job/${jobId}/output?page=1&size=${framesCount}`,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.accessToken
        }),
        context: new HttpContext().set(SHOW_LOADER, false)
      }
    );
  }

  getResultsArchive(jobId: number): Observable<HttpEvent<ArrayBuffer>> {
    return this.http.get(`${environment.baseUrl}/storage/job/${jobId}/output/archive`, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.accessToken,
        Accept: 'application/octet-stream',
        'Content-Type': 'application/octet-stream'
      }),
      context: new HttpContext().set(SHOW_LOADER, false),
      responseType: 'arraybuffer',
      observe: 'events',
      reportProgress: true
    });
  }

  removeClientResource(jobId: number, inputItemPath: string): Observable<any> {
    return this.http.delete<any>(`${environment.baseUrl}/storage/job/${jobId}?path=${inputItemPath}`, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.accessToken
      })
    });
  }

  renameClientFile(jobId: number, path: string, newResourceName: string): Observable<any> {
    return this.http.patch<any>(
      `${environment.baseUrl}/storage/job/${jobId}?path=${path}&destination=${newResourceName}`,
      {},
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.accessToken
        })
      }
    );
  }

  getUploadCreds(jobId: number): Observable<Response<ResourceUploadCreds>> {
    return this.http.get<Response<ResourceUploadCreds>>(`${environment.baseUrl}/storage/job/${jobId}/creds/upload`, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.accessToken
      })
    });
  }

  uploadFile(jobId: number, creds: ResourceUploadCreds, fileName: string, file: File | null): Promise<any> {
    console.log('File name:', fileName);

    const fullPath = this.path + fileName;
    const fullPathId = jobId + '/' + this.path + fileName;
    const xmlReq = new XMLHttpRequest();
    const formData = new FormData();
    formData.append('x-goog-date', creds['x-goog-date']);
    formData.append('x-goog-algorithm', creds['x-goog-algorithm']);
    formData.append('x-goog-credential', creds['x-goog-credential']);
    formData.append('x-goog-signature', creds['x-goog-signature']);
    formData.append('key', creds.key.replace('${filename}', fullPath));
    formData.append('policy', creds.policy);
    if (file) {
      formData.append('file', file, fullPath);
    } else {
      const emptyFolder = new File([], fullPath);
      formData.append('file', emptyFolder);
    }

    const uploadProgressData: UploadProgressData = this.uploadProgressData$.getValue();
    uploadProgressData[fullPathId] = { name: fileName, progress: { value: 0 }, canceled: false, isHidden: false };
    this.ngZone.run(() => {
      this.uploadProgressData$.next(uploadProgressData);
    });

    return new Promise<void>((resolve, reject) => {
      xmlReq.open('POST', creds.uploadLink, true);

      xmlReq.upload.onprogress = (event) => {
        if (event.lengthComputable && uploadProgressData[fullPathId]) {
          if (uploadProgressData[fullPathId].canceled) {
            xmlReq.abort();
          } else {
            const progress = Math.round((event.loaded / event.total) * 100);
            uploadProgressData[fullPathId].progress!.value = progress;
          }
          this.ngZone.run(() => {
            this.uploadProgressData$.next(uploadProgressData);
          });
        }
      };

      xmlReq.onload = () => {
        if (xmlReq.status === 200 || xmlReq.status === 204) {
          resolve();
        } else if (xmlReq.status === 500) {
          if (this.sendFileAttempt === 0) {
            this.sendFileAttempt = 3;
            reject();
          } else {
            console.log('Try to send file one more time');
            this.sendFileAttempt--;
            xmlReq.open('POST', creds.uploadLink, true);
            xmlReq.send(formData);
          }
        } else {
          if (xmlReq.status === 409) {
            this.toastrService.error(`A file with the name ${fileName} already exists`);
          }
          reject();
        }
      };

      xmlReq.onerror = (error) => {
        console.log(error);
        reject();
      };

      xmlReq.send(formData);
    });
  }
}
