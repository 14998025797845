import { Component, OnInit } from '@angular/core';
import { StorageApiService } from '../../services/storage-api.service';
import { UploadProgressData } from '../../interfaces';

@Component({
  selector: 'app-upload-progress-alerts',
  templateUrl: './upload-progress-alerts.component.html',
  styleUrls: ['./upload-progress-alerts.component.scss']
})
export class UploadProgressAlertsComponent implements OnInit {
  constructor(public storageApiService: StorageApiService) {}

  ngOnInit(): void {}

  trackByData(index: number, item: any) {
    return item.key;
  }

  removeAlert(key: string) {
    const uploadProgressData: UploadProgressData = this.storageApiService.uploadProgressData$.getValue();
    uploadProgressData[key].isHidden = true;
    this.storageApiService.uploadProgressData$.next(uploadProgressData);
  }

  cancelUpload(key: string) {
    const uploadProgressData: UploadProgressData = this.storageApiService.uploadProgressData$.getValue();
    uploadProgressData[key].canceled = true;
    this.storageApiService.uploadProgressData$.next(uploadProgressData);

    setTimeout(() => {
      if (uploadProgressData[key].canceled) {
        delete uploadProgressData[key];
        this.storageApiService.uploadProgressData$.next(uploadProgressData);
      }
    }, 3000);
  }
}
