import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { loadStripe } from '@stripe/stripe-js';
import { Subscription } from 'rxjs';
import { BalanceService } from '../../shared/services/balance.service';
import { PaymentService } from '../../shared/services/payment.service';
import { AdditionalService } from '../../shared/services/additional.service';
import { LoaderService } from '../../shared/services/loader.service';
import { PromocodeModalComponent } from '../../shared/components/modals/promocode-modal/promocode-modal.component';
import { CheckoutStatusModalComponent } from '../../shared/components/modals/checkout-status-modal/checkout-status-modal.component';
import { ClientTransactions, Button } from '../../shared/interfaces';
import { BUTTON_THEME } from '../../shared/enums';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-wallet-page',
  templateUrl: './wallet-page.component.html',
  styleUrls: ['./wallet-page.component.scss']
})
export class WalletPageComponent implements OnInit, OnDestroy {
  tableData: ClientTransactions[] = [];
  private clientTransactionsSubscription: Subscription | undefined;
  private sortParams: Sort = { active: 'date', direction: 'desc' };
  private readonly REFRESH_TRANSACTION_DATA_TIME: number = 1000 * 30;
  private refreshTransactionsDataInterval: ReturnType<typeof setInterval> = {} as ReturnType<typeof setInterval>;
  private stripePromise = loadStripe(environment.stripeApiKey);
  stripe: any;
  searchQuery: string = '';
  searchColumns: string[] = ['transaction_job', 'transaction_type'];
  currentPage: number = 1;
  itemsPerPage: number = 15;

  readonly promocodeBtn: Button = {
    name: 'Promo Code',
    iconRight: 'icon-Promocode',
    theme: BUTTON_THEME.DARK
  };
  readonly buyCoinsBtn: Button = {
    name: 'Buy Coins',
    iconRight: 'icon-Withdraw'
  };

  constructor(
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    public additionalService: AdditionalService,
    public balanceService: BalanceService,
    public paymentService: PaymentService,
    public dialogService: MatDialog,
    public loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.paymentService.getTransactionsData();
    this.refreshTransactionsDataInterval = setInterval(() => {
      this.paymentService.getTransactionsData(false);
    }, this.REFRESH_TRANSACTION_DATA_TIME);

    this.clientTransactionsSubscription = this.paymentService.clientTransactions$.subscribe((response: any) => {
      this.tableData = response;
      this.sortData(this.sortParams);
    });

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
    if (clientSecret) {
      this.checkPaymentStatus(clientSecret);
    }
  }

  openPromocodePopup() {
    this.dialogService.open(PromocodeModalComponent, { panelClass: 'dialog-overlay-pane' });
  }

  openCheckoutPage() {
    this.router.navigateByUrl('checkout');
  }

  clearSearchQuery() {
    this.searchQuery = '';
  }

  sortData(sort: Sort | any) {
    this.sortParams = sort;

    if (!sort.active || sort.direction === '') {
      return;
    }

    this.tableData = this.additionalService.sortData(sort, this.tableData);

    if (this.searchQuery !== '') {
      const tempSearchVal = this.searchQuery;
      this.searchQuery = '';
      this.changeDetector.detectChanges();
      this.searchQuery = tempSearchVal;
    }
  }

  async checkPaymentStatus(clientSecret: any) {
    this.loaderService.showLoader();

    this.stripe = await this.stripePromise;

    const { paymentIntent } = await this.stripe.retrievePaymentIntent(clientSecret);

    let dialogRef = this.dialogService.open(CheckoutStatusModalComponent, {
      panelClass: 'dialog-overlay-pane',
      data: paymentIntent
    });

    dialogRef.afterClosed().subscribe(() => {
      this.removeQueryParams();
    });

    this.loaderService.hideLoader();
  }

  removeQueryParams() {
    this.router.navigate([], {
      queryParams: {},
      replaceUrl: true
    });
  }

  ngOnDestroy(): void {
    this.dialogService.closeAll();
    this.paymentService.destroyServices();
    this.clientTransactionsSubscription?.unsubscribe();
    clearInterval(this.refreshTransactionsDataInterval);
  }
}
