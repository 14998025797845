import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';
import { ResetPassword, Button } from '../../../interfaces';
import { BUTTON_SIZE } from '../../../enums';

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss']
})
export class ResetPasswordModalComponent implements OnInit {
  private timeOut!: ReturnType<typeof setTimeout>;
  private readonly HIDE_PASSWORD_DELAY: number = 3000;

  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;

  passwordMinLength = 8;
  passwordMaxLength = 50;

  form: FormGroup = new FormGroup(
    {
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(this.passwordMinLength),
        Validators.maxLength(this.passwordMaxLength),
        Validators.pattern('^(?!\\s)(?!.*\\s$)(?!.*?(\\s)).*$'),
        this.regexValidator(new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$'), { complexity: true })
      ]),
      confirmPassword: new FormControl('', Validators.required)
    },
    { validators: this.checkPasswords }
  );

  readonly submitBtn: Button = {
    name: 'Reset Password',
    size: BUTTON_SIZE.LG
  };

  constructor(
    private authService: AuthService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<ResetPasswordModalComponent>,
    @Inject(MAT_DIALOG_DATA) public resetToken: string
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    if (this.form.valid) {
      const clientData: ResetPassword = {
        resetToken: this.resetToken,
        newPassword: this.form.get('password')?.value
      };
      this.authService.resetPassword(clientData).subscribe({
        next: (response) => {
          if (response.success) {
            this.toastrService.success('Password updated');
            this.clearResetToken();
            this.dialogRef.close();
          } else {
            this.toastrService.error(response.error?.description);
          }
        },
        error: (error) => {
          this.toastrService.error(error.message);
        }
      });
    }
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  checkPasswords(group: AbstractControl): ValidationErrors | null {
    const password = group.get('password')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { notEqual: true };
  }

  togglePassword(type: string) {
    if (type == 'new') {
      this.showNewPassword = !this.showNewPassword;
    } else {
      this.showConfirmPassword = !this.showConfirmPassword;
    }

    this.showPasswordTimeout();
  }

  showPasswordTimeout() {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.showNewPassword = false;
      this.showConfirmPassword = false;
    }, this.HIDE_PASSWORD_DELAY);
  }

  clearResetToken() {
    const urlWithoutResetToken = window.location.href.replace(/[?&]resetToken=[^&#]*/g, '');
    window.history.replaceState(null, '', urlWithoutResetToken);
  }
}
