import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProjectService } from '../../services/project.service';
import { ClientService } from '../../services/client.service';
import { BalanceService } from '../../services/balance.service';
import { ClientJobItem, ClientJobListWithDetails, Button } from '../../interfaces';
import { JOB_STATUS, BUTTON_SIZE } from '../../enums';

@Component({
  selector: 'app-status-button',
  templateUrl: './status-button.component.html',
  styleUrls: ['./status-button.component.scss']
})
export class StatusButtonComponent implements OnInit, OnDestroy {
  @Input() jobData: ClientJobItem | ClientJobListWithDetails | null = null;
  @Input() isDisabled: boolean = false;

  size: BUTTON_SIZE = this.clientService.isProjectsPage ? BUTTON_SIZE.SM : BUTTON_SIZE.MD;
  isEnoughBalance: boolean = false;
  private balanceSubscription: Subscription | undefined;

  readonly startAnalyzeBtn: Button = {
    name: 'Calculate Cost',
    iconMobile: 'icon-Calculate',
    size: this.size
  };

  readonly pauseRenderingBtn: Button = {
    name: 'Pause Rendering',
    iconMobile: 'icon-pause-icon',
    size: this.size
  };

  readonly continueRenderingBtn: Button = {
    name: 'Continue Rendering',
    iconMobile: 'icon-Start',
    size: this.size
  };

  readonly analyzeInProgressBtn: Button = {
    name: 'Analyze in progress',
    iconMobile: 'icon-Start',
    size: this.size
  };

  readonly showResultBtn: Button = {
    name: 'Show Result',
    iconMobile: 'icon-Preview',
    size: this.size
  };

  constructor(
    public projectService: ProjectService,
    public clientService: ClientService,
    private balanceService: BalanceService
  ) {}

  ngOnInit(): void {
    this.balanceSubscription = this.balanceService.balance$.subscribe((response) => {
      this.isEnoughBalance = response && response >= 15 ? true : false;
    });
  }

  get jobStatus(): typeof JOB_STATUS {
    return JOB_STATUS;
  }

  ngOnDestroy(): void {
    this.balanceSubscription?.unsubscribe();
  }
}
