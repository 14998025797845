import {
  Component,
  OnInit,
  Inject,
  AfterViewInit,
  ElementRef,
  HostListener,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ProjectService } from '../../../services/project.service';
import { StorageApiService } from '../../../services/storage-api.service';
import { AdditionalService } from '../../../services/additional.service';
import { OutputItem, Button } from '../../../interfaces';
import { BUTTON_THEME } from '../../../enums';

@Component({
  selector: 'app-image-preview-modal',
  templateUrl: './image-preview-modal.component.html',
  styleUrls: ['./image-preview-modal.component.scss']
})
export class ImagePreviewModalComponent implements OnInit, AfterViewInit, OnDestroy {
  private clientOutputItemsSubscription: Subscription | undefined;
  currentItem: OutputItem = {} as OutputItem;
  currentItemSrc: string = '';
  isPrevBtnVisible: boolean = true;
  isNextBtnVisible: boolean = true;

  private scrollToPreviewSubject = new Subject<number>();
  private debounceTimeMs = 200;

  readonly downloadImageBtn: Button = {
    name: 'Download image',
    theme: BUTTON_THEME.DARK
  };
  readonly downloadAllBtn: Button = {
    name: 'Download all'
  };

  @ViewChild('currentImage') currentImage!: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ImagePreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public currentIndex: number,
    public storageApiService: StorageApiService,
    private projectService: ProjectService,
    private additionalService: AdditionalService
  ) {}

  ngOnInit(): void {
    this.setCurrentItem(this.currentIndex);

    this.clientOutputItemsSubscription = this.storageApiService.clientOutputItems$.subscribe({
      next: (response) => {
        this.isNextBtnVisible = this.currentIndex == response.length - 1 ? false : true;
      }
    });

    this.scrollToPreviewSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe((index) => {
      document.getElementById('imagePreview' + index)?.scrollIntoView({ behavior: 'smooth' });
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.scrollToPreviewSubject.next(this.currentIndex);
    }, 1000);
  }

  onPrevClick() {
    const index = this.currentIndex - 1;
    if (this.storageApiService.clientOutputItems$.getValue()[index]) {
      this.setCurrentItem(index);
    } else {
      this.scrollToPreviewSubject.next(this.currentIndex);
    }
  }

  onNextClick() {
    const index = this.currentIndex + 1;
    if (this.storageApiService.clientOutputItems$.getValue()[index]) {
      this.setCurrentItem(index);
    } else {
      this.scrollToPreviewSubject.next(this.currentIndex);
    }
  }

  selectImage(index: number) {
    this.setCurrentItem(index);
  }

  setCurrentItem(index: number) {
    this.currentIndex = index;
    this.currentItem = this.storageApiService.clientOutputItems$.getValue()[index];
    this.currentItemSrc = this.currentItem.fullLink;
    this.isPrevBtnVisible = index == 0 ? false : true;
    this.isNextBtnVisible = index == this.storageApiService.clientOutputItems$.getValue().length - 1 ? false : true;
    this.scrollToPreviewSubject.next(this.currentIndex);
  }

  downloadImage() {
    this.storageApiService.downloadClientOutputImage(this.currentItem.fullLink);
  }

  downloadResult() {
    const fileName = this.additionalService.convertProjectName(this.projectService.selectedJobData$.getValue().name);
    this.storageApiService.downloadClientOutputResources(this.projectService.selectedJobId!, fileName);
  }

  trackByData(index: number, item: any) {
    return index;
  }

  ngOnDestroy(): void {
    this.clientOutputItemsSubscription?.unsubscribe();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') {
      this.onPrevClick();
    }
    if (event.key === 'ArrowRight') {
      this.onNextClick();
    }
  }
}
