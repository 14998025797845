import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { ProfileSettingsModalComponent } from '../modals/profile-settings-modal/profile-settings-modal.component';
import { BalanceService } from '../../services/balance.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isMobileMenuOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isProfileMenuOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public balanceService: BalanceService,
    public authService: AuthService,
    public dialogService: MatDialog
  ) {}

  ngOnInit(): void {}

  toggleMobileMenu() {
    this.isMobileMenuOpen.next(!this.isMobileMenuOpen.getValue());
  }

  closeMobileMenu() {
    this.isMobileMenuOpen.next(false);
  }

  openProfileMenu() {
    this.isProfileMenuOpen.next(!this.isProfileMenuOpen.getValue());
  }

  openProfileSettingsPopup(event: any) {
    event.stopPropagation();
    this.clickedOutside();
    this.dialogService.closeAll();
    setTimeout(() => {
      this.dialogService.open(ProfileSettingsModalComponent, { panelClass: 'dialog-overlay-pane' });
    }, 100);
  }

  clickedOutside() {
    this.isProfileMenuOpen.next(false);
  }

  logout() {
    this.dialogService.closeAll();
    this.authService.logout(true);
    window.location.href = 'https://gaimin.cloud';
  }
}
