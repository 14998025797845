import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatExpansionModule } from '@angular/material/expansion';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
import { DragScrollModule } from 'ngx-drag-scroll';
import { NgxFileDropModule } from 'ngx-file-drop';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { ProjectsPageComponent } from './pages/projects-page/projects-page.component';
import { ProjectItemPageComponent } from './pages/projects-page/project-item-page/project-item-page.component';
import { WalletPageComponent } from './pages/wallet-page/wallet-page.component';
import { CheckoutPageComponent } from './pages/checkout-page/checkout-page.component';

import { SocialsLoginComponent } from './shared/components/socials-login/socials-login.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { ProjectsTableComponent } from './shared/components/projects-table/projects-table.component';
import { ProjectItemTableComponent } from './shared/components/project-item-table/project-item-table.component';
import { RenderResultsTableComponent } from './shared/components/render-results-table/render-results-table.component';
import { JobDetailsComponent } from './shared/components/job-details/job-details.component';
import { ProgressBarComponent } from './shared/components/progress-bar/progress-bar.component';
import { ButtonComponent } from './shared/components/button/button.component';
import { StatusButtonComponent } from './shared/components/status-button/status-button.component';
import { ScrollToTopComponent } from './shared/components/scroll-to-top/scroll-to-top.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { UploadProgressAlertsComponent } from './shared/components/upload-progress-alerts/upload-progress-alerts.component';
import { CreateJobModalComponent } from './shared/components/modals/create-job-modal/create-job-modal.component';
import { CreateProjectFAQModalComponent } from './shared/components/modals/create-project-faq-modal/create-project-faq-modal.component';
import { UploadFilesModalComponent } from './shared/components/modals/upload-files-modal/upload-files-modal.component';
import { PromocodeModalComponent } from './shared/components/modals/promocode-modal/promocode-modal.component';
import { ProfileSettingsModalComponent } from './shared/components/modals/profile-settings-modal/profile-settings-modal.component';
import { ChangePasswordModalComponent } from './shared/components/modals/change-password-modal/change-password-modal.component';
import { ForgotPasswordModalComponent } from './shared/components/modals/forgot-password-modal/forgot-password-modal.component';
import { ResetPasswordModalComponent } from './shared/components/modals/reset-password-modal/reset-password-modal.component';
import { EditFileNameModalComponent } from './shared/components/modals/edit-file-name-modal/edit-file-name-modal.component';
import { BalanceWarningModalComponent } from './shared/components/modals/balance-warning-modal/balance-warning-modal.component';
import { ImagePreviewModalComponent } from './shared/components/modals/image-preview-modal/image-preview-modal.component';
import { CheckoutStatusModalComponent } from './shared/components/modals/checkout-status-modal/checkout-status-modal.component';

import { SearchPipe } from './shared/pipes/search.pipe';
import { ClickedOutsideDirective } from './shared/directives/clicked-outside.directive';
import { LoginInterceptor } from './shared/interceptors/login.interceptor';
import { LoaderInterceptor } from './shared/interceptors/loader.interceptor';
import { FileDetailsModalComponent } from "./shared/components/modals/file-details-modal/file-details-modal.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    ProjectsPageComponent,
    ProjectItemPageComponent,
    WalletPageComponent,
    CheckoutPageComponent,
    SocialsLoginComponent,
    ProjectsTableComponent,
    ProjectItemTableComponent,
    HeaderComponent,
    JobDetailsComponent,
    ProgressBarComponent,
    ButtonComponent,
    RenderResultsTableComponent,
    StatusButtonComponent,
    LoaderComponent,
    ScrollToTopComponent,
    UploadProgressAlertsComponent,
    CreateJobModalComponent,
    CreateProjectFAQModalComponent,
    UploadFilesModalComponent,
    PromocodeModalComponent,
    ProfileSettingsModalComponent,
    ChangePasswordModalComponent,
    ForgotPasswordModalComponent,
    ResetPasswordModalComponent,
    ImagePreviewModalComponent,
    EditFileNameModalComponent,
    BalanceWarningModalComponent,
    CheckoutStatusModalComponent,
    FileDetailsModalComponent,
    SearchPipe,
    ClickedOutsideDirective
  ],
  imports: [
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    DragScrollModule,
    NgxFileDropModule,
    MatTooltipModule,
    MatDialogModule,
    MatIconModule,
    MatSortModule,
    MatExpansionModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      closeButton: true,
      timeOut: 5000,
      preventDuplicates: true
    }),
    SocialLoginModule,
    GoogleSigninButtonModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoginInterceptor, multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '263013827022-pt99nf391rm607t66efmtd7pgm8oa0f5.apps.googleusercontent.com'
            )
          }
        ]
      } as SocialAuthServiceConfig
    }
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
