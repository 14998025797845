export enum JOB_STATUS {
  NEW = 'NEW',
  ANALYZE_IN_PROGRESS = 'ANALYZE_IN_PROGRESS',
  ANALYSIS_FAILED = 'ANALYSIS_FAILED',
  ANALYZED = 'ANALYZED',
  RENDERING = 'RENDERING',
  PAUSED = 'PAUSED',
  COMPLETED = 'COMPLETED'
}

export enum TRANSACTION_TYPE {
  CHARGE = 'Rendering',
  BONUS = 'Promo code',
  PAYMENT = 'Deposit Money'
}

export enum BUTTON_SIZE {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl'
}

export enum BUTTON_THEME {
  LIGHT = 'light',
  DARK = 'dark'
}

export enum BUTTON_TYPE {
  GENERAL = 'general',
  FORM = 'form',
  MODAL = 'modal',
  BACK = 'back'
}
