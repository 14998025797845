import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { AuthService } from './auth.service';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  Response,
  ClientJob,
  ClientJobListDetails,
  ClientJobListWithDetails,
  ClientJobWarnings,
  ClientJobItem,
  CreateClientJobResponse
} from '../interfaces';
import { environment } from '../../../environments/environment';
import { SHOW_LOADER } from '../interceptors/loader.interceptor';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private readonly REFRESH_CLIENT_JOB_TIME: number = 1000 * 15;
  private refreshClientJobListDetailsInterval: ReturnType<typeof setInterval> = {} as ReturnType<typeof setInterval>;
  clientJobs$: BehaviorSubject<ClientJobListWithDetails[]> = new BehaviorSubject<ClientJobListWithDetails[]>([]);
  projectSortParams: Sort = { active: 'modified', direction: 'desc' };
  isProjectsPage: boolean = false;
  searchQuery: string = '';
  prevSearchQuery: string = '';

  constructor(private http: HttpClient, private authService: AuthService) {}

  clearSearchQuery() {
    this.searchQuery = '';
  }

  refreshClientJobList(showLoader: boolean = true) {
    this.getClientJobList(showLoader).subscribe((response) => {
      if (response.success) {
        this.clientJobs$.next(response.data!);
        this.refreshClientJobListDetails(false);
      }
    });
  }

  refreshClientJobListDetails(showLoader: boolean = true) {
    this.getClientJobListDetails(showLoader).subscribe({
      next: (response) => {
        if (response.success && response.data) {
          let jobsListData = this.clientJobs$.getValue();
          jobsListData = jobsListData.map((jobData) => ({
            ...jobData,
            ...response.data!.find((jobDetails) => jobDetails.id === jobData.id)
          }));
          this.clientJobs$.next(jobsListData);

          this.getClientJobWarnings().subscribe({
            next: (response) => {
              if (response.success && response.data) {
                let jobsListData = this.clientJobs$.getValue();
                jobsListData = jobsListData.map((jobData) => ({
                  ...jobData,
                  ...response.data!.find((jobWarnings) => jobWarnings.id === jobData.id)
                }));
                this.clientJobs$.next(jobsListData);
              }
            }
          });
        }
      }
    });
  }

  turnOnJobListDetailsUpdater() {
    this.refreshClientJobListDetailsInterval = setInterval(() => {
      this.refreshClientJobListDetails(false);
    }, this.REFRESH_CLIENT_JOB_TIME);
  }

  turnOffJobListDetailsUpdater() {
    clearInterval(this.refreshClientJobListDetailsInterval);
  }

  getClientJobDetails(jobId: number): Observable<Response<ClientJobItem>> {
    return this.http.get<Response<ClientJobItem>>(`${environment.baseUrl}/client/job?jobId=${jobId}`, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.accessToken
      }),
      context: new HttpContext().set(SHOW_LOADER, false)
    });
  }

  getClientJobList(showLoader: boolean = true): Observable<Response<ClientJob[]>> {
    return this.http.get<Response<ClientJob[]>>(`${environment.baseUrl}/client/job/list`, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.accessToken
      }),
      context: new HttpContext().set(SHOW_LOADER, showLoader)
    });
  }

  getClientJobListDetails(showLoader: boolean = true): Observable<Response<ClientJobListDetails[]>> {
    return this.http.get<Response<ClientJobListDetails[]>>(`${environment.baseUrl}/client/job/detail/list`, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.accessToken
      }),
      context: new HttpContext().set(SHOW_LOADER, showLoader)
    });
  }

  getClientJobWarnings(showLoader: boolean = false): Observable<Response<ClientJobWarnings[]>> {
    return this.http.get<Response<ClientJobWarnings[]>>(`${environment.baseUrl}/client/job/warnings/list`, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.accessToken
      }),
      context: new HttpContext().set(SHOW_LOADER, showLoader)
    });
  }

  creteClientJob(name: string): Observable<Response<CreateClientJobResponse>> {
    return this.http.post<Response<CreateClientJobResponse>>(
      `${environment.baseUrl}/client/job`,
      { name },
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.accessToken
        })
      }
    );
  }

  startClientJob(jobId: number): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `${environment.baseUrl}/client/job/render?jobId=${jobId}`,
      {},
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.accessToken
        }),
        context: new HttpContext().set(SHOW_LOADER, true)
      }
    );
  }

  pauseRendering(jobId: number): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `${environment.baseUrl}/client/job/render/pause?jobId=${jobId}`,
      {},
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.accessToken
        }),
        context: new HttpContext().set(SHOW_LOADER, true)
      }
    );
  }

  removeJob(jobId: number): Observable<Response<any>> {
    return this.http.delete<Response<any>>(`${environment.baseUrl}/client/job?jobId=${jobId}`, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.accessToken
      })
    });
  }

  renameProject(jobId: number, newJobName: string): Observable<Response<any>> {
    return this.http.patch<Response<any>>(
      `${environment.baseUrl}/client/job?jobId=${jobId}`,
      { name: newJobName },
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.accessToken
        })
      }
    );
  }

  fileChangesRequest(jobId: number): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `${environment.baseUrl}/client/job/files/update?jobId=${jobId}`,
      {},
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.accessToken
        })
      }
    );
  }
}
