import { Component, OnInit, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss']
})
export class ScrollToTopComponent implements OnInit {
  windowIsScrolled: boolean = false;
  @Input() targetElement: string = '';

  constructor(@Inject(DOCUMENT) private document: Document) {}

  scrollToTop() {
    document.getElementById(this.targetElement)?.scrollIntoView({ behavior: 'smooth' });
  }

  onWindowScroll(target: any) {
    const scrollPosition = target.scrollTop || 0;
    this.windowIsScrolled = scrollPosition >= 10 ? true : false;
  }

  ngOnInit(): void {}
}
