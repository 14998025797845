import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpContext
} from '@angular/common/http';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { environment } from '../../../environments/environment';
import { LoaderService } from '../services/loader.service';
import { SHOW_LOADER } from '../interceptors/loader.interceptor';

@Injectable()
export class LoginInterceptor implements HttpInterceptor {
  private isRefreshing: boolean = false;
  private accessToken$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService, private loaderService: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401 && request.url.includes(environment.baseUrl)) {
          return this.handle401Error(request, next);
        }
        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.accessToken$.next(null);
      const refreshToken: string = this.authService.refreshToken;

      if (refreshToken) {
        return this.authService.refreshTokenRequest().pipe(
          switchMap((response) => {
            this.isRefreshing = false;
            this.authService.accessToken = response.data!.token;
            this.authService.refreshToken = response.data!.refreshToken;
            this.accessToken$.next(response.data?.token);

            return next.handle(this.addTokenHeader(request, response.data!.token));
          }),
          catchError((error) => {
            this.isRefreshing = false;
            this.authService.logout();
            return throwError(() => error);
          })
        );
      } else {
        console.log('Refresh token does`t exist');
        this.authService.logout();
      }
    }
    return this.accessToken$.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + token),
      context: new HttpContext().set(SHOW_LOADER, false)
    });
  }
}
