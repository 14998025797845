<div class="login-page">
  <div class="login-wrapper">
    <h3>Welcome</h3>
    <h2>{{ isRegisterForm ? 'Join GAIMIN.CLOUD' : 'Login to your account' }}</h2>

    <form action="" class="form form-auth" [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
      <div class="form__field-group">
        <input formControlName="email" type="text" [placeholder]="isRegisterForm ? 'Email' : 'Login'" />

        <ng-container *ngIf="form.get('email')?.invalid && form.get('email')?.dirty">
          <span *ngIf="form.get('email')?.hasError('required')" class="form__error">
            {{ isRegisterForm ? 'Email' : 'Login' }} should be present
          </span>

          <ng-container
            *ngIf="
              form.get('email')?.hasError('minlength') || form.get('email')?.hasError('maxlength');
              else email_pattern
            ">
            <span class="form__error">
              Email should have
              <mark>{{ emailMinLength }} - {{ emailMaxLength }}</mark>
              characters. You currently typed
              <mark>{{ form.get('email')?.value.length }}</mark>
            </span>
          </ng-container>

          <ng-template #email_pattern>
            <ng-container *ngIf="form.get('email')?.hasError('email')">
              <span class="form__error">Valid email address required</span>
            </ng-container>
          </ng-template>
        </ng-container>
      </div>

      <div class="form__field-group">
        <div class="password-field">
          <input formControlName="password" [type]="showPassword ? 'text' : 'password'" placeholder="Password" />
          <span
            (click)="togglePassword()"
            class="icon"
            [ngClass]="{ 'icon-Hide-Password': !showPassword, 'icon-Show-Password': showPassword }"></span>
        </div>
        <ng-container *ngIf="form.get('password')?.invalid && form.get('password')?.dirty">
          <span *ngIf="form.get('password')?.hasError('required')" class="form__error">
            Password should be present
          </span>

          <ng-container
            *ngIf="
              form.get('password')?.hasError('minlength') || form.get('password')?.hasError('maxlength');
              else password_pattern
            ">
            <span class="form__error">
              Password should have <mark>{{ passwordMinLength }} - {{ passwordMaxLength }}</mark> characters. You
              currently typed
              <mark>{{ form.get('password')?.value.length }}</mark>
            </span>
          </ng-container>

          <ng-template #password_pattern>
            <ng-container *ngIf="form.get('password')?.hasError('pattern'); else password_complexity">
              <span class="form__error">Password can have latin characters, digits and symbols</span>
            </ng-container>
          </ng-template>

          <ng-template #password_complexity>
            <ng-container *ngIf="form.get('password')?.hasError('complexity')">
              <span class="form__error"
                >Password should contain at least 1 lowercase character, 1 uppercase character, 1 digit</span
              >
            </ng-container>
          </ng-template>
        </ng-container>
      </div>

      <div class="form__field-group" *ngIf="isRegisterForm">
        <div class="password-field">
          <input
            formControlName="confirmPassword"
            [ngClass]="{ invalid: form.hasError('notEqual') }"
            [type]="showConfirmPassword ? 'text' : 'password'"
            placeholder="Confirm Password" />
          <span
            (click)="toggleConfirmPassword()"
            class="icon"
            [ngClass]="{
              'icon-Hide-Password': !showConfirmPassword,
              'icon-Show-Password': showConfirmPassword
            }"></span>
        </div>
        <ng-container
          *ngIf="
            (form.get('confirmPassword')?.invalid || form.hasError('notEqual')) && form.get('confirmPassword')?.dirty
          ">
          <span
            *ngIf="form.get('confirmPassword')?.hasError('required') && !form.hasError('notEqual')"
            class="form__error">
            Password should be present
          </span>
          <span *ngIf="form.hasError('notEqual')" class="form__error">Passwords do not match</span>
        </ng-container>
      </div>

      <a *ngIf="!isRegisterForm" class="form__forgot-link" (click)="openForgotPasswordPopup()">Forgot Password?</a>

      <app-button
        [disabled]="form.invalid"
        [attr.disabled]="form.invalid"
        [buttonData]="isRegisterForm ? signUpBtn : signInBtn"></app-button>

      <a (click)="changeFormType()" class="form__create-link">{{
        isRegisterForm ? 'Already have account' : 'Create an account'
      }}</a>
    </form>
    <app-socials-login></app-socials-login>
  </div>
</div>
