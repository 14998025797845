import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StorageApiService } from '../../services/storage-api.service';
import { ImagePreviewModalComponent } from '../modals/image-preview-modal/image-preview-modal.component';
import { Button } from '../../../shared/interfaces';
import { BUTTON_SIZE, BUTTON_THEME, BUTTON_TYPE } from '../../../shared/enums';

@Component({
  selector: 'app-render-results-table',
  templateUrl: './render-results-table.component.html',
  styleUrls: ['./render-results-table.component.scss']
})
export class RenderResultsTableComponent implements OnInit, OnDestroy {
  readonly backBtn: Button = {
    name: 'Back',
    theme: BUTTON_THEME.DARK,
    type: BUTTON_TYPE.BACK,
    size: BUTTON_SIZE.XS,
    iconLeft: 'icon-Arrow-Left'
  };

  constructor(public storageApiService: StorageApiService, public dialogService: MatDialog) {}

  ngOnInit(): void {}

  openImagePopup(index: number) {
    this.dialogService.open(ImagePreviewModalComponent, {
      panelClass: ['dialog-overlay-pane', 'dialog-render-preview'],
      data: index
    });
  }

  goBack() {
    this.storageApiService.isRenderResultsSectionOpen = false;
  }

  trackByData(index: number, item: any) {
    return index;
  }

  ngOnDestroy(): void {
    this.dialogService.closeAll();
  }
}
