<div class="job-details">
  <div class="job-details__data">
    <div class="container">
      <div class="job-details__data-btn hide-mobile">
        <app-button [buttonData]="backBtn" (click)="projectService.goBackToPrevPath()"></app-button>
      </div>
      <div class="job-details__data-progress">
        <div class="job-details__container">
          <div class="job-details__data-content">
            <div class="job-details__data-content-frames">
              <span class="percent"
                >{{
                  additionalService.getRenderPercent(
                    projectService.selectedJobData$.getValue().framesCount,
                    projectService.selectedJobData$.getValue().finishedFramesCount
                  )
                }}%</span
              >
              <span class="count"
                >{{ projectService.selectedJobData$.getValue().finishedFramesCount || 0 }} /
                {{ projectService.selectedJobData$.getValue().framesCount || 0 }} Frames</span
              >
            </div>
            <div class="job-details__data-content-cost">
              <div class="cost-item">
                <span class="title">Est. Price</span>
                <span class="value"
                  >{{ projectService.selectedJobData$.getValue().jobCost || 0 | number : '1.2-2' }}
                  <span class="icon icon-Dashboard"></span
                ></span>
              </div>
              <div class="cost-item">
                <span class="title">Spent Coins</span>
                <span class="value">
                  {{ projectService.selectedJobData$.getValue().amountSpent || 0 | number : '1.2-2' }}
                  <span class="icon icon-Dashboard"></span
                ></span>
              </div>
              <div class="status-button">
                <app-status-button
                  [jobData]="projectService.selectedJobData$.getValue()"
                  [isDisabled]="
                    !storageApiService.isBlenderFileExists ||
                    storageApiService.isJobFileUploadInprogress(projectService.selectedJobId!)
                  "></app-status-button>
              </div>
            </div>
          </div>
          <app-progress-bar
            [percent]="
              additionalService.getRenderPercent(
                projectService.selectedJobData$.getValue().framesCount,
                projectService.selectedJobData$.getValue().finishedFramesCount
              )
            "></app-progress-bar>
          <div class="job-details__data-warnings">
            <span *ngIf="!storageApiService.isBlenderFileExists"
              >Blender file not found, please upload file to start rendering</span
            >
            <span *ngFor="let warning of projectService.selectedJobData$.getValue().warnings">
              {{ warning.message }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="job-details__preview">
    <div class="container">
      <div class="job-details__container">
        <div class="job-details__preview-content">
          <h4>
            Render Result
            <span
              *ngIf="storageApiService.clientOutputItems$.getValue().length > 0"
              class="count hide-desktop hide-tablet"
              >{{ storageApiService.clientOutputItems$.getValue().length }}</span
            ><span
              *ngIf="storageApiService.clientOutputItems$.getValue().length > 0"
              class="download hide-desktop hide-tablet"
              (click)="downloadResult()"
              [ngClass]="{ disabled: isDownloadButtonDisabled }"
              >Download</span
            >
          </h4>
          <div class="job-details__preview-images-wrapper">
            <div class="job-details__preview-images" #previewContainer>
              <ng-container *ngIf="storageApiService.clientOutputItems$.getValue().length > 0; else noPreviewImages">
                <div
                  class="preview"
                  *ngFor="
                    let item of storageApiService.clientOutputItems$.getValue().slice(0, 13);
                    trackBy: trackByData;
                    let itemIndex = index
                  ">
                  <img [src]="item.thumbnailLink" (click)="openImagePopup(itemIndex)" />
                  <img [src]="item.fullLink" style="display: none" />
                </div>
              </ng-container>
            </div>
            <div
              class="job-details__preview-buttons hide-mobile"
              *ngIf="storageApiService.clientOutputItems$.getValue().length > 0">
              <span *ngIf="previewCount > 0" class="count" (click)="showRenderResults()">+{{ previewCount }}</span>
              <app-button
                [buttonData]="downloadBtn"
                (click)="downloadResult()"
                [attr.disabled]="isDownloadButtonDisabled"
                [disabled]="isDownloadButtonDisabled"></app-button>
            </div>
            <div
              class="job-details__preview-arrow hide-desktop hide-tablet"
              *ngIf="storageApiService.clientOutputItems$.getValue().length > 0 && previewCount > 0">
              <span class="icon-Arrow-Right" (click)="showRenderResults()"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #noPreviewImages>
  <div class="preview no-preview" *ngFor="let number of additionalService.placeholderCounter(placeholderCount)">
    <span class="icon-Close"></span>
  </div>
</ng-template>
