import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/auth.guard';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { ProjectsPageComponent } from './pages/projects-page/projects-page.component';
import { ProjectItemPageComponent } from './pages/projects-page/project-item-page/project-item-page.component';
import { WalletPageComponent } from './pages/wallet-page/wallet-page.component';
import { CheckoutPageComponent } from './pages/checkout-page/checkout-page.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: 'projects', pathMatch: 'full' },
      {
        path: 'projects',
        canActivate: [AuthGuard],
        pathMatch: 'prefix',
        component: ProjectsPageComponent
      },
      {
        path: 'projects/:id',
        canActivate: [AuthGuard],
        pathMatch: 'prefix',
        component: ProjectItemPageComponent
      },
      {
        path: 'wallet',
        canActivate: [AuthGuard],
        component: WalletPageComponent
      },
      {
        path: 'checkout',
        canActivate: [AuthGuard],
        component: CheckoutPageComponent
      },
      {
        path: 'login',
        component: LoginPageComponent
      },
      {
        path: 'client/password/reset',
        component: LoginPageComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
