<button
  [ngClass]="[
    buttonData.type ? 'button_' + buttonData.type : 'button_general',
    buttonData.size ? 'button_' + buttonData.size : 'button_md',
    buttonData.theme ? 'button_' + buttonData.theme : 'button_light'
  ]"
  class="button"
  [disabled]="disabled">
  <span
    *ngIf="buttonData.iconMobile"
    class="icon icon-mobile hide-desktop hide-tablet"
    [ngClass]="buttonData.iconMobile"></span>
  <span *ngIf="buttonData.iconLeft" class="icon icon-left" [ngClass]="buttonData.iconLeft"></span>
  <span *ngIf="buttonData.name" class="text" [ngClass]="{ 'hide-mobile': buttonData.iconMobile }">{{
    buttonData.name
  }}</span>
  <span
    *ngIf="buttonData.iconRight && buttonData.iconRight !== 'spinner'"
    class="icon icon-right"
    [ngClass]="buttonData.iconRight"></span>
  <div *ngIf="buttonData.iconRight === 'spinner'" class="button__spinner">
    <div class="spinner">
      <div class="spinner__circle">
        <div class="spinner__circle-gradient"></div>
        <div class="spinner__circle-inner"></div>
      </div>
    </div>
  </div>
</button>
