import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ResourceItem } from "../../../interfaces";
import { ProjectService } from "../../../services/project.service";

@Component({
  selector: 'app-file-details-modal',
  templateUrl: './file-details-modal.component.html',
  styleUrls: ['./file-details-modal.component.scss']
})
export class FileDetailsModalComponent implements OnInit {
  constructor(
    private projectService: ProjectService,
    @Inject(MAT_DIALOG_DATA) public file: ResourceItem
  ) {}

  ngOnInit(): void {}

  get mainFileVersion() {
    const version = this.projectService.selectedJobData$.getValue()?.mainFileVersion;
    const mainFilePath = this.projectService.selectedJobData$.getValue()?.mainFilePath;
    return version && mainFilePath === (this.file.path + this.file.name) ? version : null
  }

  formatBytes(bytes: number, decimals = 2) {
    if (!bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }
}
