<div class="preview-carousel">
  <div class="header">
    <div class="header-buttons">
      <app-button [buttonData]="downloadImageBtn" (click)="downloadImage()"></app-button>
      <app-button [buttonData]="downloadAllBtn" (click)="downloadResult()"></app-button>
    </div>
    <span class="close-icon icon-Close-Bold" mat-dialog-close></span>
  </div>
  <div class="carousel-wrapper">
    <div class="preview">
      <img [src]="currentItemSrc" alt="" #currentImage />
      <div class="controls icon-prev" (click)="onPrevClick()" *ngIf="isPrevBtnVisible">
        <span class="icon-Arrow-Left"></span>
      </div>
      <div class="controls icon-next" (click)="onNextClick()" *ngIf="isNextBtnVisible">
        <span class="icon-Arrow-Right"></span>
      </div>
      <div class="preview-title">
        <span class="title">{{
          currentItem.fullLink.substring(
            currentItem.fullLink.indexOf('/output/') + 8,
            currentItem.fullLink.indexOf('?')
          )
        }}</span>
      </div>
    </div>
    <div class="items">
      <drag-scroll [drag-scroll-y-disabled]="true" [scroll-x-wheel-enabled]="true" [snap-disabled]="true">
        <div
          class="item"
          drag-scroll-item
          *ngFor="
            let item of storageApiService.clientOutputItems$.getValue();
            trackBy: trackByData;
            let itemIndex = index
          ">
          <img
            [src]="item.thumbnailLink"
            [id]="'imagePreview' + itemIndex"
            [ngClass]="{ active: currentIndex == itemIndex }"
            (click)="selectImage(itemIndex)"
            alt="" /></div
      ></drag-scroll>
    </div>
  </div>
</div>
