<div class="project-item" [ngClass]="{ 'render-results-hidden': !storageApiService.isRenderResultsSectionOpen }">
  <app-job-details [ngClass]="{ 'hide-mobile': storageApiService.isRenderResultsSectionOpen }"></app-job-details>
  <div class="container">
    <div class="project-item-btn hide-desktop hide-tablet" *ngIf="!storageApiService.isRenderResultsSectionOpen">
      <app-button [buttonData]="backBtn" (click)="projectService.goBackToPrevPath()"></app-button>
    </div>
    <h2 class="page-title hide-desktop hide-tablet" *ngIf="!storageApiService.isRenderResultsSectionOpen">
      {{ projectService.selectedJobData$.getValue().name }}
    </h2>
    <app-project-item-table *ngIf="!storageApiService.isRenderResultsSectionOpen"></app-project-item-table>
    <app-render-results-table
      id="render-results-table"
      *ngIf="storageApiService.isRenderResultsSectionOpen"></app-render-results-table>
  </div>
</div>
