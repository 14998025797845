import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../services/loader.service';
import { ProjectService } from '../../services/project.service';
import { ClientService } from '../../../shared/services/client.service';
import { StorageApiService } from '../../services/storage-api.service';
import { AdditionalService } from '../../services/additional.service';
import { ImagePreviewModalComponent } from '../modals/image-preview-modal/image-preview-modal.component';
import { Button, OutputItem, UploadProgressData } from '../../../shared/interfaces';
import { BUTTON_THEME, BUTTON_TYPE, BUTTON_SIZE } from '../../../shared/enums';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements AfterViewInit, OnInit, OnDestroy {
  clientOutputItemsCount: number = 0;
  previewCount: number = 0;
  placeholderCount: number = 17;
  isDownloadButtonDisabled: boolean = false;

  readonly backBtn: Button = {
    name: 'Back',
    theme: BUTTON_THEME.DARK,
    type: BUTTON_TYPE.BACK,
    size: BUTTON_SIZE.XS,
    iconLeft: 'icon-Arrow-Left'
  };

  readonly downloadBtn: Button = {
    name: 'Download',
    theme: BUTTON_THEME.DARK
  };

  private clientOutputItemsSubscription: Subscription | undefined;

  @ViewChild('previewContainer') previewContainer!: ElementRef;

  constructor(
    public projectService: ProjectService,
    public clientService: ClientService,
    public storageApiService: StorageApiService,
    public additionalService: AdditionalService,
    public loaderService: LoaderService,
    public dialogService: MatDialog
  ) {}

  ngOnInit(): void {
    this.storageApiService.uploadProgressData$.subscribe((data: UploadProgressData) => {
      if (this.projectService.selectedJobData$.getValue().name) {
        const fileName = this.additionalService.convertProjectName(
          this.projectService.selectedJobData$.getValue().name
        );
        const archiveName = `GAIMIN_RNDR_${fileName}.zip`;
        if (data[archiveName] && !data[archiveName].canceled && !data[archiveName].loading?.isCompleted) {
          this.isDownloadButtonDisabled = true;
        } else {
          this.isDownloadButtonDisabled = false;
        }
      }
    });
  }

  ngAfterViewInit() {
    this.clientOutputItemsSubscription = this.storageApiService.clientOutputItems$.subscribe(
      (response: OutputItem[]) => {
        this.clientOutputItemsCount = response.length;
        this.getPreviewCount(this.clientOutputItemsCount);
      }
    );
  }

  getPreviewCount(responseCount: number) {
    const singleItemWidth = 40;
    const singleItemMargin = 7;
    if (this.previewContainer) {
      const previewContainerWidth = this.previewContainer.nativeElement.offsetWidth;
      const itemsInRowCount = Math.floor(
        (previewContainerWidth + singleItemMargin) / (singleItemWidth + singleItemMargin)
      );
      this.previewCount = responseCount - itemsInRowCount;
    }
  }

  downloadResult() {
    const fileName = this.additionalService.convertProjectName(this.projectService.selectedJobData$.getValue().name);
    this.storageApiService.downloadClientOutputResources(this.projectService.selectedJobId!, fileName);
  }

  showRenderResults() {
    this.storageApiService.isRenderResultsSectionOpen = true;
    // window.scroll({
    //   top: 0,
    //   behavior: 'smooth'
    // });
  }

  openImagePopup(index: number) {
    this.dialogService.open(ImagePreviewModalComponent, {
      panelClass: ['dialog-overlay-pane', 'dialog-render-preview'],
      data: index
    });
  }

  trackByData(index: number, item: any) {
    return index;
  }

  ngOnDestroy(): void {
    this.dialogService.closeAll();
    this.clientOutputItemsSubscription?.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.getPreviewCount(this.clientOutputItemsCount);
  }
}
