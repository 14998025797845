import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { Response, PaymentIntent, ClientTransactions } from '../interfaces';
import { environment } from '../../../environments/environment';
import { SHOW_LOADER } from '../interceptors/loader.interceptor';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  clientTransactions$: BehaviorSubject<ClientTransactions[]> = new BehaviorSubject<ClientTransactions[]>([]);
  private transactionsSubscription: Subscription | undefined;

  constructor(private http: HttpClient, private toastrService: ToastrService, private authService: AuthService) {}

  getClientSecret(): Observable<Response<PaymentIntent>> {
    return this.http.post<Response<PaymentIntent>>(
      `${environment.baseUrl}/client/payments`,
      {},
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.accessToken
        })
      }
    );
  }

  updatePaymentAmount(amount: number, paymentId: string): Observable<Response<PaymentIntent>> {
    return this.http.put<Response<PaymentIntent>>(
      `${environment.baseUrl}/client/payments/${paymentId}`,
      {
        amount: amount,
        currency: 'USD'
      },
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.accessToken
        })
      }
    );
  }

  sendPromocode(code: string): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `${environment.baseUrl}/client/payment/promo-code`,
      {
        code: code
      },
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.accessToken
        })
      }
    );
  }

  getTransactionsData(isShowLoader: boolean = true) {
    this.transactionsSubscription = this.getClientTransactions(isShowLoader).subscribe({
      next: (response) => {
        if (response.success && response.data) {
          this.clientTransactions$.next(
            response.data.filter((item: any) => {
              return item.amount >= 0.01;
            })
          );
        } else {
          this.toastrService.error(response.error?.description);
        }
      }
    });
  }

  getClientTransactions(isShowLoader: boolean = true): Observable<Response<ClientTransactions[]>> {
    return this.http.get<Response<ClientTransactions[]>>(`${environment.baseUrl}/client/transactions`, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.accessToken
      }),
      context: new HttpContext().set(SHOW_LOADER, isShowLoader)
    });
  }

  destroyServices() {
    this.transactionsSubscription?.unsubscribe();
  }
}
