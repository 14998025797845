import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Buffer } from 'buffer';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { ForgotPasswordModalComponent } from '../../shared/components/modals/forgot-password-modal/forgot-password-modal.component';
import { ResetPasswordModalComponent } from '../../shared/components/modals/reset-password-modal/reset-password-modal.component';
import { Button, LoginClient, RegisterClient } from '../../shared/interfaces';
import { BUTTON_SIZE, BUTTON_TYPE } from '../../shared/enums';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {
  private readonly HIDE_PASSWORD_DELAY: number = 3000;
  private timeOut!: ReturnType<typeof setTimeout>;
  resetToken: string | null = null;

  private resetTokenSubscription: Subscription | undefined;

  readonly signInBtn: Button = {
    name: 'Sign in',
    type: BUTTON_TYPE.FORM,
    size: BUTTON_SIZE.XL
  };

  readonly signUpBtn: Button = {
    name: 'Sign up',
    type: BUTTON_TYPE.FORM,
    size: BUTTON_SIZE.XL
  };

  isRegisterForm: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;

  emailMinLength = 3;
  emailMaxLength = 200;
  passwordMinLength = 8;
  passwordMaxLength = 50;

  form: FormGroup = new FormGroup({});

  loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', Validators.required)
  });

  registerForm: FormGroup = new FormGroup(
    {
      email: new FormControl('', [
        Validators.required,
        Validators.minLength(this.emailMinLength),
        Validators.maxLength(this.emailMaxLength),
        Validators.email
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(this.passwordMinLength),
        Validators.maxLength(this.passwordMaxLength),
        Validators.pattern('^(?!\\s)(?!.*\\s$)(?!.*?(\\s)).*$'),
        this.regexValidator(new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$'), { complexity: true })
      ]),
      confirmPassword: new FormControl('', Validators.required)
    },
    { validators: this.checkPasswords }
  );

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private toastrService: ToastrService,
    public dialogService: MatDialog
  ) {}

  ngOnInit(): void {
    this.form = this.loginForm;
    if (this.authService.isLoggedIn$.getValue()) {
      this.router.navigateByUrl('projects');
    }

    this.resetTokenSubscription = this.activatedRoute.queryParams.subscribe((params) => {
      this.resetToken = params['resetToken'];
      if (this.resetToken) {
        const splitToken = this.resetToken.split('.');
        if (splitToken.length > 1) {
          const decodedPayload = JSON.parse(Buffer.from(splitToken[1], 'base64').toString('ascii'));
          if (decodedPayload.exp && decodedPayload.exp > Date.now() / 1000) {
            this.openResetPasswordPopup();
          } else {
            this.toastrService.error(
              'Sorry, your password reset link has expired. Please request a new link to reset your password.'
            );
            this.router.navigateByUrl('login');
          }
        }
      }
    });
  }

  onSubmit() {
    if (this.isRegisterForm) {
      const clientData: RegisterClient = {
        email: this.registerForm.get('email')?.value,
        password: this.registerForm.get('password')?.value
      };
      this.authService.registerClient(clientData).subscribe({
        next: (response) => {
          if (response.success) {
            this.authService.accessToken = response.data!.token;
            this.authService.refreshToken = response.data!.refreshToken;
            this.authService.isLoggedIn$.next(true);
            this.router.navigateByUrl('projects');
          } else {
            this.toastrService.error(response.error?.description);
          }
        },
        error: (error) => {
          this.toastrService.error(error.message);
        }
      });
    } else {
      const clientData: LoginClient = {
        login: this.loginForm.get('email')?.value,
        password: this.loginForm.get('password')?.value
      };
      this.authService.loginClient(clientData).subscribe({
        next: (response) => {
          if (response.success) {
            this.authService.accessToken = response.data!.token;
            this.authService.refreshToken = response.data!.refreshToken;
            this.authService.isLoggedIn$.next(true);
            this.router.navigateByUrl('projects');
          } else {
            this.toastrService.error(response.error?.description);
          }
        },
        error: (error) => {
          this.toastrService.error(error.message);
        }
      });
    }
  }

  openForgotPasswordPopup() {
    this.dialogService.open(ForgotPasswordModalComponent, { panelClass: 'dialog-overlay-pane' });
  }

  openResetPasswordPopup() {
    this.dialogService.open(ResetPasswordModalComponent, { panelClass: 'dialog-overlay-pane', data: this.resetToken });
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  checkPasswords(group: AbstractControl): ValidationErrors | null {
    const password = group.get('password')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { notEqual: true };
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
    this.showPasswordTimeout();
  }

  toggleConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
    this.showPasswordTimeout();
  }

  showPasswordTimeout() {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.showPassword = false;
      this.showConfirmPassword = false;
    }, this.HIDE_PASSWORD_DELAY);
  }

  changeFormType(): void {
    this.isRegisterForm = !this.isRegisterForm;
    this.form = this.isRegisterForm ? this.registerForm : this.loginForm;
  }

  ngOnDestroy(): void {
    this.dialogService.closeAll();
    this.resetTokenSubscription?.unsubscribe();
  }
}
