<ng-container *ngIf="jobData" [ngSwitch]="jobData.status">
  <div
    *ngSwitchCase="jobStatus.NEW"
    class="status-button"
    [matTooltip]="
      isEnoughBalance
        ? 'The estimated cost for rendering will be provided after 1% of the file is analyzed'
        : 'Please ensure that you have a balance of at least 15 coins to initiate the cost calculation process.'
    "
    [matTooltipPosition]="clientService.isProjectsPage ? 'above' : 'below'"
    [matTooltipClass]="'custom-tooltip'">
    <app-button
      [buttonData]="startAnalyzeBtn"
      [disabled]="isDisabled || !isEnoughBalance"
      [attr.disabled]="isDisabled || !isEnoughBalance"
      (click)="projectService.calculateCost(jobData.id)"></app-button>
  </div>
  <div
    *ngSwitchCase="jobStatus.ANALYZE_IN_PROGRESS"
    class="status-button"
    [matTooltip]="'The estimated cost for rendering will be provided after 1% of the file is analyzed'"
    [matTooltipPosition]="clientService.isProjectsPage ? 'above' : 'below'"
    [matTooltipClass]="'custom-tooltip'">
    <app-button [buttonData]="analyzeInProgressBtn" [disabled]="true" [attr.disabled]="true"></app-button>
  </div>
  <div
    *ngSwitchCase="jobStatus.ANALYSIS_FAILED"
    class="status-button"
    [matTooltip]="'The file analysis failed, change it to the new one'"
    [matTooltipPosition]="clientService.isProjectsPage ? 'above' : 'below'"
    [matTooltipClass]="'custom-tooltip'">
    <app-button [buttonData]="continueRenderingBtn" [disabled]="true"></app-button>
  </div>
  <div *ngSwitchCase="jobStatus.ANALYZED" class="status-button">
    <app-button
      [buttonData]="continueRenderingBtn"
      [disabled]="isDisabled"
      [attr.disabled]="isDisabled"
      (click)="projectService.analyzeBalance(jobData)"></app-button>
  </div>
  <div *ngSwitchCase="jobStatus.PAUSED" class="status-button">
    <app-button
      [buttonData]="continueRenderingBtn"
      [disabled]="isDisabled"
      [attr.disabled]="isDisabled"
      (click)="projectService.analyzeBalance(jobData)"></app-button>
  </div>
  <div *ngSwitchCase="jobStatus.RENDERING" class="status-button">
    <app-button
      [buttonData]="pauseRenderingBtn"
      [disabled]="isDisabled"
      [attr.disabled]="isDisabled"
      (click)="projectService.pauseRendering(jobData.id)"></app-button>
  </div>
  <div *ngSwitchCase="jobStatus.COMPLETED" class="status-button">
    <app-button [buttonData]="showResultBtn" (click)="projectService.showResult(jobData.id)"></app-button>
  </div>
</ng-container>
