import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { AdditionalService } from '../../../services/additional.service';
import { StorageApiService } from '../../../services/storage-api.service';
import { ProjectService } from '../../../services/project.service';
import { Button, ClientJobItem, UploadedFiles } from '../../../interfaces';
import { BUTTON_SIZE } from '../../../enums';

@Component({
  selector: 'app-upload-files-modal',
  templateUrl: './upload-files-modal.component.html',
  styleUrls: ['./upload-files-modal.component.scss']
})
export class UploadFilesModalComponent implements OnInit {
  public files: NgxFileDropEntry[] = [];
  public convertedFiles: UploadedFiles[] = [];

  readonly submitBtn: Button = {
    name: 'Upload Files',
    size: BUTTON_SIZE.LG
  };

  constructor(
    public additionalService: AdditionalService,
    public projectService: ProjectService,
    public storageApiService: StorageApiService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<UploadFilesModalComponent>
  ) {}

  ngOnInit(): void {}

  droppedFiles(files: NgxFileDropEntry[]) {
    this.files = [...this.files, ...files];
    this.files = this.additionalService.removeRepeatedFiles(this.files);
    this.convertedFiles = this.additionalService.convertFilesArray(this.files);
    console.log(this.files);
  }

  onSubmit() {
    const jobData: ClientJobItem = this.projectService.selectedJobData$.getValue();
    if (this.additionalService.isEnableToEditResources) {
      this.projectService.uploadFiles(jobData.id, this.files, false);
    } else {
      const jobStatus: string = this.additionalService.normalizeStatus(jobData.status);
      this.toastrService.warning(`The project status is "${jobStatus}"`, 'The file could not be uploaded');
    }
    this.dialogRef.close();
  }
  removeFile(index: number, name: string) {
    this.files = this.files.filter((file) => file.relativePath !== name);
    this.convertedFiles.splice(index, 1);
  }
}
