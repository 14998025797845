import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';
import { ChangePassword, Button } from '../../../interfaces';
import { BUTTON_SIZE } from '../../../enums';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {
  private timeOut!: ReturnType<typeof setTimeout>;
  private readonly HIDE_PASSWORD_DELAY: number = 3000;

  showOldPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;

  passwordMinLength = 8;
  passwordMaxLength = 50;

  form: FormGroup = new FormGroup(
    {
      oldPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(this.passwordMinLength),
        Validators.maxLength(this.passwordMaxLength)
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(this.passwordMinLength),
        Validators.maxLength(this.passwordMaxLength),
        Validators.pattern('^(?!\\s)(?!.*\\s$)(?!.*?(\\s)).*$'),
        this.regexValidator(new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$'), { complexity: true })
      ]),
      confirmPassword: new FormControl('', Validators.required)
    },
    { validators: this.checkPasswords }
  );

  readonly submitBtn: Button = {
    name: 'Change Password',
    size: BUTTON_SIZE.LG
  };

  constructor(
    private authService: AuthService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<ChangePasswordModalComponent>
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    if (this.form.valid) {
      const clientData: ChangePassword = {
        oldPassword: this.form.get('oldPassword')?.value,
        newPassword: this.form.get('password')?.value
      };
      this.authService.changePassword(clientData).subscribe({
        next: (response) => {
          if (response.success) {
            this.toastrService.success('Password was changed successfully.');
            this.dialogRef.close();
          } else {
            this.toastrService.error(response.error?.description);
          }
        },
        error: (error) => {
          this.toastrService.error(error.message);
        }
      });
    }
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  checkPasswords(group: AbstractControl): ValidationErrors | null {
    const password = group.get('password')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { notEqual: true };
  }

  togglePassword(type: string) {
    switch (type) {
      case 'old':
        this.showOldPassword = !this.showOldPassword;
        break;
      case 'new':
        this.showNewPassword = !this.showNewPassword;
        break;
      default:
        this.showConfirmPassword = !this.showConfirmPassword;
        break;
    }

    this.showPasswordTimeout();
  }

  showPasswordTimeout() {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.showOldPassword = false;
      this.showNewPassword = false;
      this.showConfirmPassword = false;
    }, this.HIDE_PASSWORD_DELAY);
  }
}
