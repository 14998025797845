import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { CreateProjectFAQModalComponent } from '../create-project-faq-modal/create-project-faq-modal.component';
import { ClientService } from '../../../services/client.service';
import { AdditionalService } from '../../../services/additional.service';
import { StorageApiService } from '../../../services/storage-api.service';
import { ProjectService } from '../../../services/project.service';
import { Button, UploadedFiles } from '../../../interfaces';
import { BUTTON_SIZE } from '../../../enums';

@Component({
  selector: 'app-create-job-modal',
  templateUrl: './create-job-modal.component.html',
  styleUrls: ['./create-job-modal.component.scss']
})
export class CreateJobModalComponent implements OnInit {
  public files: NgxFileDropEntry[] = [];
  public convertedFiles: UploadedFiles[] = [];

  form: FormGroup = new FormGroup({
    projectName: new FormControl('', [Validators.required, Validators.maxLength(100), this.checkUniqueName()])
  });

  readonly submitBtn: Button = {
    name: 'Create Project',
    size: BUTTON_SIZE.LG
  };

  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private clientService: ClientService,
    public additionalService: AdditionalService,
    public projectService: ProjectService,
    public storageApiService: StorageApiService,
    public dialogService: MatDialog,
    public dialogRef: MatDialogRef<CreateJobModalComponent>
  ) {}

  ngOnInit(): void {}

  droppedFiles(files: NgxFileDropEntry[]) {
    this.files = [...this.files, ...files];
    this.files = this.additionalService.removeRepeatedFiles(this.files);
    this.convertedFiles = this.additionalService.convertFilesArray(this.files);
    console.log(this.files);
  }

  onSubmit() {
    if (this.form.valid) {
      this.clientService.creteClientJob(this.form.get('projectName')?.value).subscribe({
        next: (response) => {
          if (response.success && response.data?.name) {
            this.toastrService.success(`Project "${response.data.name}" created successfully`);
            const jobId = response.data.id;
            this.projectService.uploadFiles(jobId, this.files, true);
            this.clientService.prevSearchQuery = this.clientService.searchQuery;
            this.clientService.searchQuery = '';
            this.router.navigateByUrl(`/projects/${jobId}`);
            this.dialogRef.close();
          } else {
            this.toastrService.error(response.error?.description);
          }
        },
        error: (error) => {
          this.toastrService.error(error.message);
        }
      });
    }
  }

  removeFile(index: number, name: string) {
    this.files = this.files.filter((file) => file.relativePath !== name);
    this.convertedFiles.splice(index, 1);
  }

  checkUniqueName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isJobNameExist: boolean = this.clientService.clientJobs$
        .getValue()
        .some((job) => job.name === control.value);
      return !isJobNameExist ? null : { notEqual: true };
    };
  }

  openCreateProjectFAQPopup() {
    this.dialogService.open(CreateProjectFAQModalComponent, { panelClass: 'dialog-overlay-pane' });
  }
}
