import { Component, OnInit } from '@angular/core';
import { BalanceService } from './shared/services/balance.service';
import { AuthService } from './shared/services/auth.service';
import { loadStripe } from '@stripe/stripe-js/pure';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private balanceService: BalanceService, public authService: AuthService) {}

  ngOnInit(): void {
    this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      console.log('Is user logged in:', isLoggedIn);
      if (isLoggedIn) {
        this.balanceService.refreshBalance();
        this.authService.getProfileInfoData();
      } else {
        setTimeout(() => {
          const container = document.getElementById('credential_picker_container');
          if (container !== null) {
            container.style.display = 'block';
          }
        }, 4000);
      }
    });
  }
}
