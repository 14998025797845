import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from '../../../services/payment.service';
import { Button } from '../../../interfaces';
import { BalanceService } from '../../../services/balance.service';
import { BUTTON_SIZE } from '../../../enums';

@Component({
  selector: 'app-promocode-modal',
  templateUrl: './promocode-modal.component.html',
  styleUrls: ['./promocode-modal.component.scss']
})
export class PromocodeModalComponent implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({
    promocode: new FormControl('', [Validators.required])
  });

  readonly submitBtn: Button = {
    name: 'Apply',
    size: BUTTON_SIZE.LG
  };

  private promocodeSubscription: Subscription | undefined;

  constructor(
    private paymentService: PaymentService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<PromocodeModalComponent>,
    private balanceService: BalanceService
  ) {}

  ngOnInit(): void {}

  getCoins() {
    if (this.form.valid) {
      this.promocodeSubscription = this.paymentService.sendPromocode(this.form.get('promocode')?.value).subscribe({
        next: (response) => {
          if (response.success) {
            this.toastrService.success('Promo code applied successfully!');
            this.dialogRef.close();
            this.paymentService.getTransactionsData();
            this.balanceService.refreshBalance();
          } else {
            this.toastrService.error(response.error?.description);
          }
        },
        error: (error) => {
          this.toastrService.error(error.message);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.promocodeSubscription?.unsubscribe();
  }
}
