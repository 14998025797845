<div class="checkout">
  <div class="checkout-form">
    <div class="checkout-form-container">
      <div class="checkout-form-button">
        <app-button class="hide-tablet hide-desktop" [buttonData]="backBtn" (click)="redirectToWallet()"></app-button>
      </div>
      <h2 class="page-title">Buy Coins</h2>

      <div class="checkout-form-coins">
        <form action="" class="form form-general" [formGroup]="form">
          <div class="form__field-group">
            <h3>Coins</h3>
            <div class="input-container">
              <input
                formControlName="amount"
                id="amount"
                type="text"
                [placeholder]="'Amount'"
                (input)="onAmountChange($event)" />
              <span class="description">1 Coin = $1</span>
            </div>
            <div class="form__error" *ngIf="form.get('amount')?.invalid && form.get('amount')?.dirty">
              <div *ngIf="form.get('amount')?.hasError('required')">Amount should be present</div>
              <div *ngIf="form.get('amount')?.hasError('pattern')">Amount should have only numbers</div>
              <div *ngIf="form.get('amount')?.hasError('min')">The minimum amount is $1</div>
              <div *ngIf="form.get('amount')?.hasError('max')">The maximum amount is $100000</div>
            </div>
          </div>
          <div class="form__field-group">
            <h3>Email</h3>
            <input formControlName="email" type="text" [placeholder]="'Email'" [attr.disabled]="true" />
          </div>
        </form>
      </div>
      <h3>Payment Card</h3>
      <div class="checkout-form-stripe">
        <form id="payment-form">
          <div id="payment-element">
            <!--Stripe.js injects the Payment Element-->
          </div>
        </form>
      </div>
      <ul class="hide-desktop">
        <li>
          <span class="title">{{ amount$ | async }} Coins</span>
          <span class="value">${{ amount$ | async }}</span>
        </li>
        <li>
          <span class="title">Total</span>
          <span class="value">${{ amount$ | async }}</span>
        </li>
      </ul>
      <app-button
        class="hide-desktop"
        [buttonData]="makePaymentBtn"
        [attr.disabled]="form.invalid"
        [disabled]="form.invalid"
        (click)="submitPayment()"></app-button>
    </div>
  </div>
  <div class="checkout-details">
    <div class="checkout-details-container">
      <div class="checkout-details-img">
        <h2>Buy Coins to Improve your render experiences</h2>
        <img class="hide-tablet hide-mobile" src="./assets/imgs/buy-coins-bg-desktop.png" alt="" />
        <img class="hide-desktop" src="./assets/imgs/buy-coins-bg-mobile.png" alt="" />
      </div>
      <ul class="hide-tablet hide-mobile">
        <li>
          <span class="title">{{ amount$ | async }} Coins</span>
          <span class="value">${{ amount$ | async }}</span>
        </li>
        <li>
          <span class="title">Total</span>
          <span class="value">${{ amount$ | async }}</span>
        </li>
      </ul>
      <app-button
        class="hide-tablet hide-mobile"
        [buttonData]="makePaymentBtn"
        [attr.disabled]="form.invalid"
        [disabled]="form.invalid"
        (click)="submitPayment()"></app-button>
    </div>
  </div>
</div>
