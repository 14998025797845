<div class="balance-section">
  <div class="container">
    <div class="balance">
      <div class="balance-value">
        <span *ngIf="(balanceService.balance$ | async) != null">{{
          balanceService.balance$ | async | number : '1.0-2'
        }}</span>
        <span class="icon icon-Dashboard"></span>
      </div>
      <div class="balance-title">Your Balance</div>
      <div class="balance-buttons">
        <app-button [buttonData]="promocodeBtn" (click)="openPromocodePopup()" class="promocode"></app-button>
        <app-button [buttonData]="buyCoinsBtn" (click)="openCheckoutPage()" class="coins"></app-button>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="transactions">
    <div class="table-section">
      <div class="table-section__header with-title">
        <h3 class="table-section__header-title">History</h3>
        <div class="table-section__header-search">
          <input type="text" [(ngModel)]="searchQuery" placeholder="Search" (ngModelChange)="currentPage = 1" />
          <span class="icon-Search"></span
          ><span
            class="icon-Close close-icon hide-desktop"
            *ngIf="searchQuery != ''"
            (click)="clearSearchQuery()"></span>
        </div>
      </div>
      <div class="table-section__body">
        <table
          class="table"
          *ngIf="tableData.length > 0; else emptyTable"
          matSort
          matSortDisableClear
          (matSortChange)="sortData($event)">
          <thead>
            <tr>
              <th mat-sort-header="transaction_job" class="wide">Name</th>
              <th mat-sort-header="amount">Amount</th>
              <th mat-sort-header="date">Date</th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngFor="
                let item of tableData
                  | search : searchColumns : searchQuery
                  | paginate : { itemsPerPage: itemsPerPage, currentPage: currentPage }
              ">
              <tr>
                <td>
                  <div class="tag-container">
                    <div class="text-container">
                      <div class="center">
                        <span class="icon icon-Dollar hide-mobile"></span>
                        <span class="name">{{ additionalService.getTransactionName(item.type.code) }}</span>
                        <span
                          *ngIf="item.type.type == 'CREDIT'"
                          class="icon up icon-Arrow-Increase hide-desktop hide-tablet"></span>
                        <span
                          *ngIf="item.type.type == 'DEBIT'"
                          class="icon down icon-Arrow-Decrease hide-desktop hide-tablet"></span>
                        <span class="amount hide-desktop hide-tablet"
                          >{{ item.type.type == 'CREDIT' ? '+' : '-' }}{{ item.amount | number : '1.0-2' }}</span
                        >
                      </div>
                      <span class="date nowrap hide-desktop hide-tablet">{{
                        item.date | date : 'dd.MM.yy, HH:mm'
                      }}</span>
                    </div>
                    <div *ngIf="item.job" class="tag">
                      <span>{{ item.job.name }}</span>
                    </div>
                  </div>
                </td>
                <td class="nowrap hide-mobile">
                  <div class="center">
                    <span *ngIf="item.type.type == 'CREDIT'" class="icon up icon-Arrow-Increase"></span>
                    <span *ngIf="item.type.type == 'DEBIT'" class="icon down icon-Arrow-Decrease"></span>
                    {{ item.type.type == 'CREDIT' ? '+' : '-' }}{{ item.amount | number : '1.0-2' }}
                  </div>
                </td>
                <td class="nowrap hide-mobile">{{ item.date | date : 'dd.MM.yy, HH:mm' }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <pagination-controls
          class="pagination"
          *ngIf="tableData.length > 0"
          (pageChange)="currentPage = $event"></pagination-controls>

        <ng-template #emptyTable>
          <div class="table__empty">
            <span *ngIf="!(loaderService.loading$ | async)">No data found</span>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
